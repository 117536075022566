import React, { useEffect, useState } from "react";

import cascading from "../assests/css/buyticketstyle.module.css";
import { FaRegQuestionCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import {
  ApproveToken,
  BuyTickets,
  GetallowanceAmount,
  allowanceAmount,
  checkIsApproved,
  getAccount,
  getCakeAddress,
  getLotteryInfo,
  getOperatorTreasuryInjectorAddress,
  getTokenBalance,
} from "../hooks/useLottery";
import { getWalletAddress } from "../lib/localStorage";
import { Logger } from "ethers/lib/utils";
import { LotterytokenSymbol } from "../config/lottery";
import toast from "react-hot-toast";
import { iconTheme, position, style } from "../hooks/useToast";
import Yourwallet from "./yourwallet";

export default function Buyticketmodal(props) {
  // state
  const [buyticketmodal, setBuyticketmodal] = useState(true);
  const [LotteryAmount, setLotteryAmount] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [CakeAddress, setCakeAddress] = useState("");
  const [accountInfo, setaccountInfo] = useState(
    sessionStorage.getItem("accountInfo")
  );
  const [CakeAddressBalance, setCakeAddressBalance] = useState(0);
  const [ValidateError, setValidateError] = useState({});
  const [youwillget, setYouWillGet] = useState(0);
  const [Allowance, setAllowance] = useState(0);
  const [lotteryPrice, setlotteryPrice] = useState(0);
  const [ticketNumber, setticketNumber] = useState("");

  useEffect(() => {
    getLotteryData();
    checkIsApproval();
  }, []);

  console.log("initial_ticketNumber", ticketNumber);

  const getLotteryData = async () => {
    var token = await getCakeAddress();
    setCakeAddress(token);
    var tokenBalance = await getTokenBalance(accountInfo);
    setCakeAddressBalance(parseFloat(tokenBalance / 10 ** 18).toFixed(3));
    var lotteryinfo = await getLotteryInfo(accountInfo);
    setlotteryPrice(
      parseFloat(lotteryinfo?.viewcurrentLottery?.priceTicketInCake) / 10 ** 18
    );
  };

  const checkIsApproval = async () => {
    var token = await getCakeAddress();
    const approval = await checkIsApproved(getWalletAddress(), token);
    setIsApproved(approval);
    const allowanceamount = await GetallowanceAmount(getWalletAddress(), token);
    setAllowance(allowanceamount);
  };

  const Approve = async () => {
    if (accountInfo) {
      await ApproveToken(accountInfo, CakeAddress);
      setIsApproved(true);
    }
  };

  const inputChange = async (e) => {
    // debugger

    if (e && e.target && e.target.name) {
      var value = e.target.value;

      switch (e.target.name) {
        case "LotteryAmount":
          if (value != "" && isNaN(value) == false && value > 0) {
            console.log("if");
            setLotteryAmount(value);
            ValidateError.LotteryAmount = "";
            let calc = await PriceCalculate({ LotteryAmount: value });
            setValidateError(ValidateError);
            let estimate = calc * 10 ** 18;
            let myallowance = parseFloat(Allowance);
            if (estimate < myallowance) {
              setIsApproved(true);
            }
            var ticketArray = [];

            for (var i = 0; i < value; i++) {
              var randomNo = Math.floor(
                Math.random() * (1999999 - 1000000 + 1)
              );
              var randomNo =
                parseFloat(randomNo.toString().split("").reverse().join("")) *
                Math.sign(randomNo);
              var final = randomNo + 1000000;
              ticketArray.push(final);
              setticketNumber(final);
            }
            console.log("finalfinal", final);
          } else {
            ValidateError.LotteryAmount = "Enter Valid price";
            setValidateError(ValidateError);
            setLotteryAmount(value);
          }
          break;
      }
      // window.$('#Validation_PlaceABid').click();
      if (value > 100) {
        ValidateError.LotteryAmount = "Please enter equal or below 100";
        setValidateError(ValidateError);
        return false;
      }
      if (value < 1) {
        ValidateError.LotteryAmount = "Please enter greater than 1";
        setValidateError(ValidateError);
        return false;
      }
    }
  };

  const PriceCalculate = async (data = {}) => {
    var price =
      typeof data.LotteryAmount != "undefined"
        ? data.LotteryAmount
        : LotteryAmount;
    var totamount = lotteryPrice * price;
    console.log("lotteryPrice,", lotteryPrice, price);
    setYouWillGet(totamount);
    return totamount;
  };

  const buyTickets = async () => {
    var ticketArray = [];

    for (var i = 0; i < LotteryAmount; i++) {
      var randomNo = Math.floor(Math.random() * (1999999 - 1000000 + 1));
      var randomNo =
        parseFloat(randomNo.toString().split("").reverse().join("")) *
        Math.sign(randomNo);
      var final = randomNo + 1000000;
      ticketArray.push(final);
      setticketNumber(final);
      console.log("ticketArray", ticketArray);
      var buynow = await BuyTickets(ticketArray, accountInfo);
    }
  };

  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal
          centered
          show={buyticketmodal}
          onHide={props.onHide}
          className={`${cascading.modalstyle}`}
          backdrop="static"
        >
          <Modal.Header
            className={`${cascading.modalclr} ${cascading.modalheader}`}
          >
            <h4 className={`${cascading.modaltitle} mb-0`}>Buy Tickets</h4>
            <IoMdClose
              className={`${cascading.closebtn}`}
              onClick={props.onHide}
            />
          </Modal.Header>
          <Modal.Body
            className={`${cascading.modalclr} ${cascading.modalbody}`}
          >
            <p className={`${cascading.innerhead}`}>Buy:</p>

            <div className={`${cascading.inputsec} `}>
              <div className={`${cascading.inputsecrow}`}>
                <div>
                  <input
                    type="text"
                    className={`from-control`}
                    id="LotteryAmount"
                    name="LotteryAmount"
                    aria-describedby="emailHelp"
                    placeholder="0.0"
                    value={LotteryAmount}
                    onChange={inputChange}
                  />
                </div>
              </div>
              <div className={`${cascading.balancerow}`}>
                <p className={`${cascading.inputlabel} mb-0 mt-2`}>
                  -{youwillget} {LotterytokenSymbol}
                </p>
              </div>
            </div>
            {ValidateError.LotteryAmount && (
              <span className="text-danger">
                <br />
                {ValidateError.LotteryAmount}
              </span>
            )}

            <p className={`${cascading.balancetext}`}>
              {LotterytokenSymbol} Balace: {CakeAddressBalance}
            </p>

            <ul className={`${cascading.button_ul}`}>
              <li>
                <button className={`btn me-2 ${cascading.buttonstyle_ul}`}>
                  1
                </button>
              </li>
              <li>
                <button className={`btn me-2 ${cascading.buttonstyle_ul}`}>
                  Max
                </button>
              </li>
            </ul>

            <div className="row mt-3">
              <div className="col-8 col-md-7">
                <p className={`mb-0 ${cascading.innerheadwhitesm}`}>
                  Cost {`(${LotterytokenSymbol})`}
                </p>
              </div>
              <div className="col-4 col-md-5">
                <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>
                  {youwillget} {LotterytokenSymbol}
                </p>
              </div>
            </div>

            {/* <div className='row mt-3'>
              <div className='col-8 col-md-7'>
                <p className={`mb-0 ${cascading.innerheadwhitesm}`}>0% Bulk discount
                  <OverlayTrigger
                    className={`${cascading.tooltip_style}`}
                    key="bottom"
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        <p className="mb-2">Buying multiple tickets in a single transaction gives a discount. The discount increases in a linear way, upto the maximum of 100 tickets:</p>
                        <p className="mb-1">2 tickets: 0.05%</p>
                        <p className="mb-1">50 tickets: 2.45%</p>
                        <p className="mb-1">100 tickets: 4.95%</p>

                      </Tooltip>
                    }
                  >
                    <Button className={`${cascading.tooltip_button} ${cascading.tooltip_button_blk}`}>
                      <FaRegQuestionCircle />
                    </Button>
                  </OverlayTrigger></p>
              </div>
              <div className='col-4 col-md-5'>
                <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>~0 {LotterytokenSymbol}</p>
              </div>
            </div> */}

            <hr />

            <div className="row mt-3">
              <div className="col-8 col-md-7">
                <p className={`mb-0 ${cascading.innerheadwhitesm}`}>You pay</p>
              </div>
              <div className="col-4 col-md-5">
                <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>
                  {youwillget.toFixed(2)} {LotterytokenSymbol}
                </p>
              </div>
            </div>

            {isApproved == true && parseInt(Allowance) > 0 == true ? (
              <button
                className={`btn mt-4 ${cascading.connectwalletbtn}`}
                type="button"
                onClick={() => buyTickets()}
              >
                Confirm
              </button>
            ) : (
              <>
                <button
                  className={`btn mt-4 ${cascading.connectwalletbtn}`}
                  type="button"
                  // onClick={props.onHide}
                  onClick={() => Approve()}
                >
                  Approve
                </button>
              </>
            )}

            {isApproved == true &&
            parseInt(Allowance) > 0 == true &&
            parseFloat(LotteryAmount) > 0 ? (
              <button
                className={`btn mt-2 ${cascading.cancelbtn}`}
                type="button"
                onClick={() => {
                  props.onShowEdit();
                  props.TicketNumberData(ticketNumber);
                  props.LotteryAmount(LotteryAmount);
                }}
              >
                View/ Edit Numbers
              </button>
            ) : (
              <></>
            )}

            <p className={`${cascading.head} mt-4`}>
              "Buy Instantly" chooses random numbers, with no duplicates among
              your tickets. Prices are set before each round starts, equal to $5
              at that time purchases are final.
            </p>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
