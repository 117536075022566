import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import cascading from "../assests/css/calculatorstyle.module.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FaRegQuestionCircle } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa6";
import { FaPencilAlt } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import Switch from "react-switch";
import Expertsmodal from "./expertsmodal";
import metaicon from "../assests/images/metamask.png";
import walleticon from "../assests/images/walletconnect.png";
import BigNumber from "bignumber.js";

//hooks
import {
  getApy,
  getInterestBreakdown,
  getPrincipalForInterest,
  getRoi,
} from "../hooks/useFarms";
import { Balance } from "../hooks/useErc20";

import { IoMdClose } from "react-icons/io";
import { toFixedNumber } from "../lib/FixedNumber";
import { isEmpty } from "../lib/isEmpty";

const compoundingIndexToFrequency = {
  0: 1,
  1: 0.142857142,
  2: 0.071428571, // once every 7 days
  3: 0.033333333, // once every 30 days
};
export default function Calculatormodal(props) {
  const [checked, setChecked] = useState(false);
  const [valuechange, setValuechange] = useState("");
  const [inputedit, setInputedit] = useState(false);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const [calculatormodal, setCalculatormodal] = useState(true);
  const [data, setData] = useState();
  const [APY, setAPY] = useState();
  const [bal, setBal] = useState();
  const [tokenval, settokenval] = useState();
  const [usdval, setusdval] = useState();
  const [princimode, setPrincimode] = useState(true);
  const [roimode, setRoimode] = useState(false);
  const [compounding, setCompounding] = useState(0);
  const [isChecked, setIsChecked] = useState(true);

  const [compoundingFrequency, setCompoundingFrequency] = useState(1);
  const [performanceFee, setPerformanceFee] = useState(0);
  const [stakingDuration, setStakingDuration] = useState(3);
  const [roiUSD, setRoiUSD] = useState(0);
  const [roiTokens, setroiTokens] = useState(0);
  const [roipercen, Setroipercen] = useState(0);

  useEffect(() => {
    console.log(
      "caluculator",
      props,
      props?.earningTokenPrice,
      props?.stakingTokenPrice
    );
    // setData(props?.farms?.data)
    apy();
  }, [props]);

  const apy = () => {
    let autoCompoundFrequency = 0;
    let performanceFee = 0;
    let APY = getApy(
      (props?.apy,
      autoCompoundFrequency > 0 ? autoCompoundFrequency : 1,
      365,
      performanceFee) * 100
    ).toFixed(2);
    console.log("APY", APY);
    setAPY(APY);
  };

  const getBalance = async () => {
    var bal = await Balance(props?.address);
    console.log("bal", bal);
    settokenval(bal);
    setPrincipalFromTokenValue(bal);
  };

  // useEffect(() => {
  //   getBalance()
  // }, [bal])

  // useEffect(() => {
  //   setPrincipalFromUSDValue(0.1)
  //   setPrincipalFromTokenValue(bal)
  // }, [bal])

  const setPrincipalFromUSDValue = (amount) => {
    console.log(amount, "setPrincipalFromUSDValue");
    const principalAsTokenBN = new BigNumber(amount).div(
      props?.stakingTokenPrice
    );
    const principalAsToken =
      principalAsTokenBN > 0
        ? toFixedNumber(principalAsTokenBN.toNumber())
        : "0.00";
    console.log("principalAsToken", principalAsToken, principalAsTokenBN);
    settokenval(principalAsToken);
    setusdval(amount);
  };

  const setPrincipalFromTokenValue = (amount) => {
    console.log(amount, "setPrincipalFromTokenValue");
    const principalAsUsdBN = new BigNumber(amount).times(
      props?.stakingTokenPrice
    );
    const principalAsUsdString =
      principalAsUsdBN > 0
        ? toFixedNumber(principalAsUsdBN.toNumber())
        : "0.00";
    console.log(
      "principalAsUsdString",
      principalAsUsdString,
      new BigNumber(principalAsUsdBN.toNumber()),
      props?.stakingTokenPrice
    );
    settokenval(amount);
    setusdval(principalAsUsdString);
  };

  // Calculates and sets ROI whenever related values change
  useEffect(() => {
    if (princimode == true) {
      const principalInUSDAsNumber = toFixedNumber(usdval);
      console.log(
        "principalInUSDAsNumber",
        principalInUSDAsNumber,
        stakingDuration,
        compoundingFrequency
      );
      const compoundFrequency = compounding ? compoundingFrequency : 0;
      console.log("compoundFrequency", compoundFrequency);
      const interestBreakdown = getInterestBreakdown({
        principalInUSD: principalInUSDAsNumber,
        apr: props?.apy,
        earningTokenPrice: props?.earningTokenPrice,
        compoundFrequency: compoundFrequency,
        performanceFee: performanceFee,
      });
      console.log(
        "interestBreakdown",
        interestBreakdown,
        interestBreakdown[stakingDuration]
      );

      const hasInterest = !Number.isNaN(interestBreakdown[stakingDuration]);
      console.log("hasInterest", hasInterest);

      const roiTokens = hasInterest ? interestBreakdown[stakingDuration] : 0;
      console.log("roiTokens", roiTokens);

      const roiAsUSD = hasInterest ? roiTokens * props?.earningTokenPrice : 0;
      console.log("roiAsUSD", roiAsUSD);

      setRoiUSD(toFixedNumber(roiAsUSD));
      setroiTokens(toFixedNumber(roiTokens));
      const roiPercentage = hasInterest
        ? getRoi({
            amountEarned: roiAsUSD,
            amountInvested: principalInUSDAsNumber,
          })
        : 0;
      console.log("roiPercentage", roiPercentage);
      Setroipercen(roiPercentage);
    }
  }, [
    usdval,
    props?.apy,
    stakingDuration,
    props?.earningTokenPrice,
    performanceFee,
    compounding,
    compoundingFrequency,
    princimode,
  ]);

  // Calculates and sets principal based on expected ROI value
  useEffect(() => {
    if (roimode == true) {
      const principalForExpectedRoi = getPrincipalForInterest(
        roiUSD,
        props?.apy,
        compounding ? compoundingFrequency : 0,
        performanceFee
      );
      console.log(principalForExpectedRoi, "principalForExpectedRoi");
      const principalUSD = !Number.isNaN(
        principalForExpectedRoi[stakingDuration]
      )
        ? principalForExpectedRoi[stakingDuration]
        : 0;
      console.log(principalUSD, "principalUSD");
      setusdval(principalUSD);
      const principalToken = new BigNumber(principalUSD).div(
        props?.stakingTokenPrice
      );
      console.log(principalToken, "principalToken");
      settokenval(toFixedNumber(principalToken.toNumber()));
      const roiPercentage = getRoi({
        amountEarned: roiUSD,
        amountInvested: principalUSD,
      });
      Setroipercen(roiPercentage);
      console.log(roiPercentage, "roiPercentage");
    }
  }, [
    stakingDuration,
    props?.apy,
    compounding,
    compoundingFrequency,
    roimode,
    roiUSD,
    props?.stakingTokenPrice,
    performanceFee,
  ]);

  const onCompoundingChange = (index) => {
    try {
      setCompounding(index);
      setCompoundingFrequency(compoundingIndexToFrequency[index]);
    } catch (err) {
      console.log(err, "onCompoundingChange___err");
    }
  };

  const onStakingDurationChange = (index) => {
    try {
      console.log("onStakingDurationChange", index);
      setStakingDuration(index);
    } catch (err) {
      console.log(err, "onStakingDurationChange__err");
    }
  };

  const RoiChange = (e) => {
    try {
      const { value, name } = e.target;
      const targetRoiAsTokens = new BigNumber(value).div(
        props?.earningTokenPrice
      );
      setRoiUSD(value);
      setroiTokens(toFixedNumber(targetRoiAsTokens.toNumber()));
    } catch (err) {
      console.log(err, "onStakingDurationChange__err");
    }
  };

  // console.log("rios",roiUSD,roimode,roiTokens)
  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal
          centered
          show={calculatormodal}
          onHide={props.onHide}
          className={`${cascading.modalstyle}`}
          backdrop="static"
        >
          <Modal.Header
            className={`${cascading.modalclr} ${cascading.modalheader}`}
          >
            <h4 className={`${cascading.modaltitle} mb-0`}>ROI Calculator</h4>
            <IoMdClose
              className={`${cascading.closebtn}`}
              onClick={props.onHide}
            />
          </Modal.Header>
          <Modal.Body
            className={`pb-0 ${cascading.modalclr} ${cascading.modalbody}`}
          >
            <p className={`${cascading.innerheadwhitesm}`}>
              {props?.symbol} STAKED
            </p>

            <div className={`${cascading.cardCalculator}`}>
              <div className="d-flex justify-content-end align-items-center">
                <div className="d-flex flex-column">
                  <div className="d-flex">
                    <input
                      className={`form-control ${cascading.calculatorinput}`}
                      value={valuechange ? usdval : tokenval}
                      placeholder="0.0"
                      onFocus={() => {
                        setPrincimode(true);
                        setRoimode(false);
                      }}
                      // onBlur={() => {
                      //   setPrincimode(false)
                      // }}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (valuechange) {
                          setPrincipalFromUSDValue(value);
                        } else {
                          setPrincipalFromTokenValue(value);
                        }
                      }}
                    />
                    <span className={`${cascading.spancal}`}>
                      {valuechange ? "USD" : props?.symbol}
                    </span>
                  </div>
                  <div>
                    <p className={`mb-0 ${cascading.spandesc}`}>
                      {valuechange
                        ? isEmpty(tokenval)
                          ? "0.0"
                          : tokenval
                        : isEmpty(usdval)
                        ? "0.0"
                        : usdval}{" "}
                      {valuechange ? props?.symbol : "USD"}
                    </p>
                  </div>
                </div>
                <button
                  className={`mb-0 ${cascading.btnicon}`}
                  onClick={() => {
                    setValuechange(!valuechange);
                  }}
                >
                  <svg
                    viewBox="0 0 24 25"
                    color="textSubtle"
                    width="20px"
                    xmlns="http://www.w3.org/2000/svg"
                    class="sc-bdnxRM bNbPUR"
                  >
                    <path
                      d="M16 17.01V11C16 10.45 15.55 10 15 10C14.45 10 14 10.45 14 11V17.01H12.21C11.76 17.01 11.54 17.55 11.86 17.86L14.65 20.64C14.85 20.83 15.16 20.83 15.36 20.64L18.15 17.86C18.47 17.55 18.24 17.01 17.8 17.01H16ZM8.65003 3.35002L5.86003 6.14002C5.54003 6.45002 5.76003 6.99002 6.21003 6.99002H8.00003V13C8.00003 13.55 8.45003 14 9.00003 14C9.55003 14 10 13.55 10 13V6.99002H11.79C12.24 6.99002 12.46 6.45002 12.14 6.14002L9.35003 3.35002C9.16003 3.16002 8.84003 3.16002 8.65003 3.35002Z"
                      fill="#fff"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>

            <ul className={`mt-4 ${cascading.button_ul}`}>
              <li>
                <button
                  className={`btn me-1 me-sm-2 ${cascading.buttonstyle_ul}`}
                  onClick={() => {
                    setPrincipalFromUSDValue(100);
                  }}
                >
                  $100
                </button>
              </li>
              <li>
                <button
                  className={`btn me-1 me-sm-2 ${cascading.buttonstyle_ul}`}
                  onClick={() => {
                    setPrincipalFromUSDValue(1000);
                  }}
                >
                  $1000
                </button>
              </li>
              <li>
                <button
                  className={`btn me-1 ${cascading.buttonstyle_ul} ${cascading.buttonstyle_ul_pink}`}
                  onClick={() => {
                    getBalance();
                  }}
                >
                  My Balance
                </button>
                <OverlayTrigger
                  className={`${cascading.tooltip_style}`}
                  key="bottom"
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      “My Balance” here includes both LP Tokens in your wallet,
                      and LP Tokens already staked in this farm.
                    </Tooltip>
                  }
                >
                  <Button className={`${cascading.tooltip_button}`}>
                    <FaRegQuestionCircle />
                  </Button>
                </OverlayTrigger>
              </li>
            </ul>

            <p className={`${cascading.innerheadwhitesm}`}>Staked for</p>

            <ul className={`mt-4 ${cascading.button_ul_new}`}>
              <li>
                <button
                  className={
                    stakingDuration == 0
                      ? `btn ${cascading.buttonstyle_ul_new} ${cascading.active}`
                      : `btn ${cascading.buttonstyle_ul_new}`
                  }
                  onClick={() => {
                    onStakingDurationChange(0);
                  }}
                >
                  1D
                </button>
              </li>
              <li>
                <button
                  className={
                    stakingDuration == 1
                      ? `btn ${cascading.buttonstyle_ul_new} ${cascading.active}`
                      : `btn ${cascading.buttonstyle_ul_new}`
                  }
                  onClick={() => {
                    onStakingDurationChange(1);
                  }}
                >
                  7D
                </button>
              </li>
              <li>
                <button
                  className={
                    stakingDuration == 2
                      ? `btn ${cascading.buttonstyle_ul_new} ${cascading.active}`
                      : `btn ${cascading.buttonstyle_ul_new}`
                  }
                  onClick={() => {
                    onStakingDurationChange(2);
                  }}
                >
                  30D
                </button>
              </li>
              <li>
                <button
                  className={
                    stakingDuration == 3
                      ? `btn ${cascading.buttonstyle_ul_new} ${cascading.active}`
                      : `btn ${cascading.buttonstyle_ul_new}`
                  }
                  onClick={() => {
                    onStakingDurationChange(3);
                  }}
                >
                  1Y
                </button>
              </li>
              <li>
                <button
                  className={
                    stakingDuration == 4
                      ? `btn ${cascading.buttonstyle_ul_new} ${cascading.active}`
                      : `btn ${cascading.buttonstyle_ul_new}`
                  }
                  onClick={() => {
                    onStakingDurationChange(4);
                  }}
                >
                  5Y
                </button>
              </li>
            </ul>

            <p className={`mt-4 ${cascading.innerheadwhitesm}`}>
              Compounding every
            </p>
            <div className="d-flex align-items-center justify-content-between">
              <div className="form-check mt-4 mb-4">
                {console.log(
                  "setCompounding",
                  compounding && compounding !== "" ? true : false,
                  isChecked,
                  compounding
                )}
                <input
                  className={`form-check-input ${cascading.checkboxstyle}`}
                  type="checkbox"
                  id="flexCheckDefault"
                  checked={isChecked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setIsChecked(e.target.checked);
                      setCompounding(0);
                    } else {
                      setCompounding("");
                      setIsChecked(e.target.checked);
                    }
                  }}
                />
                <label
                  className={`form-check-label mb-0 mt-0 ${cascading.innerheadwhite}`}
                  for="flexCheckDefault"
                ></label>
              </div>
              <ul className={`mt-3 w-100 ms-3 ${cascading.button_ul_new}`}>
                <li>
                  <button
                    className={
                      compounding == 0
                        ? `btn ${cascading.buttonstyle_ul_new} ${cascading.active}`
                        : `btn ${cascading.buttonstyle_ul_new}`
                    }
                    onClick={() => {
                      onCompoundingChange(0);
                    }}
                  >
                    1D
                  </button>
                </li>
                <li>
                  <button
                    className={
                      compounding == 1
                        ? `btn ${cascading.buttonstyle_ul_new} ${cascading.active}`
                        : `btn ${cascading.buttonstyle_ul_new}`
                    }
                    onClick={() => {
                      onCompoundingChange(1);
                    }}
                  >
                    7D
                  </button>
                </li>
                <li>
                  <button
                    className={
                      compounding == 2
                        ? `btn ${cascading.buttonstyle_ul_new} ${cascading.active}`
                        : `btn ${cascading.buttonstyle_ul_new}`
                    }
                    onClick={() => {
                      onCompoundingChange(2);
                    }}
                  >
                    14D
                  </button>
                </li>
                <li>
                  <button
                    className={
                      compounding == 3
                        ? `btn ${cascading.buttonstyle_ul_new} ${cascading.active}`
                        : `btn ${cascading.buttonstyle_ul_new}`
                    }
                    onClick={() => {
                      onCompoundingChange(3);
                    }}
                  >
                    30D
                  </button>
                </li>
              </ul>
            </div>
            <div className="p-3 text-center">
              <FaArrowDown className={`${cascading.arrowdowntext}`} />
            </div>

            <div className={`${cascading.pinkcardmodal}`}>
              <p className={`mb-0 text-uppercase ${cascading.themelabel}`}>
                ROI at current rates
              </p>
              {!roimode ? (
                <div className="d-flex align-items-center justify-content-between">
                  <p
                    className={`mb-0 text-uppercase ${cascading.innerheadwhitelg} ${cascading.wirdbraktxt}`}
                  >
                    ${toFixedNumber(roiUSD)}
                  </p>
                  <button
                    className={`${cascading.btniconnew}`}
                    onClick={() => {
                      setPrincimode(false);
                      setRoimode(true);
                    }}
                  >
                    <FaPencilAlt />
                  </button>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-between">
                  <input
                    type="text"
                    className={`form-control ${cascading.sliipage_input}`}
                    value={roiUSD}
                    onFocus={() => {
                      setRoimode(true);
                    }}
                    // onBlur={() => {
                    //   setRoimode(false)
                    // }}
                    onChange={(e) => {
                      RoiChange(e);
                      // const targetRoiAsTokens = new BigNumber(amount).div(earningTokenPrice)
                    }}
                  />
                  <button
                    className={`${cascading.btniconnew}`}
                    onClick={() => {
                      setRoimode(false);
                    }}
                  >
                    <FaCheck />
                  </button>
                </div>
              )}

              <p
                className={`mb-0 text-uppercase ${cascading.themelabel} ${cascading.wirdbraktxt}`}
              >
                ~ {toFixedNumber(roiTokens)} {props?.rewardToken} (
                {!isNaN(roipercen) ? toFixedNumber(roipercen) : 0}%)
              </p>
            </div>

            <div className={`row ${cascading.rowmarginminus}`}>
              <Accordion defaultActiveKey="1" className="mt-4 rounded-0">
                {props?.isFarm ? (
                  <Accordion.Item
                    eventKey="0"
                    className={`${cascading.accordionitem}`}
                  >
                    <Accordion.Header className={`${cascading.accordionhead}`}>
                      Details
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className={`${cascading.gridtext}`}>
                        <p>APR (incl. LP rewards)</p>
                        <p className="text-end">
                          {parseFloat(props?.apy) !== Infinity
                            ? parseFloat(props?.apy).toLocaleString("en-Us")
                            : 0}{" "}
                          %
                        </p>
                        <p>Base APR ({props?.rewardToken} yield only)</p>
                        <p className="text-end">
                          {parseFloat(props?.apy) !== Infinity
                            ? parseFloat(props?.apy).toLocaleString("en-Us")
                            : 0}{" "}
                          %
                        </p>
                        <p>APY (1x daily compound)</p>
                        <p className="text-end">{APY} %</p>
                        <p>Farm Multiplier</p>
                        <p className="text-end">
                          {props?.multiplier}
                          <OverlayTrigger
                            className={`${cascading.tooltip_style}`}
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                The Multiplier represents the proportion of{" "}
                                {props?.rewardToken} rewards each farm receives,
                                as a proportion of the {props?.rewardToken}{" "}
                                produced each block. This amount is already
                                included in all APR calculations for the farm.
                              </Tooltip>
                            }
                          >
                            <Button
                              className={`${cascading.tooltip_button} ${cascading.tooltip_button_minus}`}
                            >
                              <FaRegQuestionCircle
                                fill="#7645d9"
                                className="mt-1"
                              />
                            </Button>
                          </OverlayTrigger>
                        </p>
                      </div>
                      <ul className={`${cascading.gridul}`}>
                        <li>Calculated based on current rates.</li>
                        <li>
                          LP rewards: 0.17% trading fees, distributed
                          proportionally among LP token holders.
                        </li>
                        <li>
                          All figures are estimates provided for your
                          convenience only, and by no means represent guaranteed
                          returns.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : (
                  <Accordion.Item
                    eventKey="0"
                    className={`${cascading.accordionitem}`}
                  >
                    <Accordion.Header className={`${cascading.accordionhead}`}>
                      Details
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className={`${cascading.gridtext}`}>
                        <p>APR </p>
                        <p className="text-end">
                          {parseFloat(props?.apy).toLocaleString("en-Us")} %
                        </p>

                        <p>APY (1x daily compound)</p>
                        <p className="text-end">{APY} %</p>
                      </div>
                      <ul className={`${cascading.gridul}`}>
                        <li>Calculated based on current rates.</li>
                        <li>
                          All figures are estimates provided for your
                          convenience only, and by no means represent guaranteed
                          returns.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
