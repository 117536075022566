import { CURRENT_CHAIN_ID, UseWeb3 ,useWeb3} from "./useWeb3"
import BigNumber from "bignumber.js"

import XtokenABI from '../config/abi/XtokenABI.json'
import contractAddress from '../config/constant/contract'

//lib
import { consolelog } from "../lib/consolelog"

//hooks
import { EstGas } from "./useCommon"
import { multicall } from "./useMultiCall"
import { toFixedNumber } from "../lib/toFixedOf"

export const getXAddress  =()=>{
    try{
        const CHAINID =  CURRENT_CHAIN_ID()
        let xtokenAddress = contractAddress.xtoken[`${CHAINID}`]
        return xtokenAddress
    }catch(err){
        consolelog('getXAddress__err',err,true)
    }
}

export const useXcontract = async()=>{
    const web3 = await useWeb3()
    try{
        let xtokenAddress = getXAddress()
        let contract =  new web3.eth.Contract(XtokenABI,xtokenAddress)
        return contract
    }catch(err){
        consolelog('Xcontract__err',err,true)
        return false
    }
}

export const XBalance  = async (address) => {
    const web3 = await useWeb3()
    try {
        let contract = await useXcontract()
        let balance = await contract.methods.balanceOf(web3.utils.toChecksumAddress(address)).call()
        return balance
    } catch (err) {
        consolelog('Xcontract__err', err, true)
        return false
    }
}

export const ConvertToken = async (amount, account) => {
    const web3 = await useWeb3()
    try {
        let contract = await useXcontract()
        let params = [amount]
        const {gasLimit,gasPrice}  = await  EstGas(params,XtokenABI,getXAddress(),'convert',account)
        let convertX = await contract.methods.convert(amount).send({ from: web3.utils.toChecksumAddress(account),gasLimit:gasLimit,gasPrice:gasPrice})
        return convertX
    } catch (err) {
        consolelog('Xcontract__err', err, true)
        return false
    }
}

export const RedeemToken =  async(amount,duration,account)=>{
    const web3 = await useWeb3()
    try {
        let contract = await useXcontract()
        let params = [amount,duration]
        const {gasLimit,gasPrice}  = await  EstGas(params,XtokenABI,getXAddress(),'redeem',account)
        let redeemNative = await contract.methods.redeem(amount,duration).send({ from: web3.utils.toChecksumAddress(account),gasLimit:gasLimit,gasPrice:gasPrice})
        return redeemNative
    } catch (err) {
        consolelog('Xcontract__err', err, true)
        return false
    }  
}


export const getXtokenInfo  = async()=>{
    try{
        let Calls = [
            {
                address:getXAddress(),
                name  :"maxRedeemDuration"
            },
            {
                address:getXAddress(),
                name  :"minRedeemDuration"
            },
            {
                address:getXAddress(),
                name  :"minRedeemRatio"     
            },
            {
                address:getXAddress(),
                name  :"maxRedeemRatio"
            }    
        ]
        let data = await multicall(XtokenABI,Calls)
        consolelog('multicall__data', data, true)
        return {
            maxRedeemDuration : new BigNumber(data[0]).toNumber(),
            minRedeemDuration: new BigNumber(data[1]).toNumber(),
            minRedeemRatio : new BigNumber(data[2]).toNumber(),
            maxRedeemRatio : new BigNumber(data[3]).toNumber()
        }
        
    }catch(err){
        consolelog('getXtokenInfo__err', err, true)
        return false
    }
}

export const getXtokenusage = async(account)=>{
    let Calls = [
        {
            address:getXAddress(),
            name  :"getXGrailBalance",
            params:[account]
        },
        {
            address:getXAddress(),
            name  :"balanceOf",
            params:[account]
        }   
    ]
    let data = await multicall(XtokenABI,Calls)
    let val = data[0][1]
    consolelog('multicall__data', {data:data,datas:new BigNumber(data[0][0]._hex).toNumber(),val:new BigNumber(val).toString()}, true,)
    return {
        allocatedAmount : toFixedNumber(new BigNumber(data[0][0]._hex).toNumber()/10**18),
        redeemingAmount: toFixedNumber(new BigNumber(data[0][1]._hex).toNumber()/10**18),
        balance : toFixedNumber(new BigNumber(data[1]).toNumber()/10**18),
    }
}

export const GetTokenOut =  async(amount,duration,account)=>{
    const web3 = await useWeb3()
    try{
        let contract = await useXcontract()
        let outAmount = await contract.methods.getGrailByVestingDuration(amount,duration).call({from:web3.utils.toChecksumAddress(account)})
        consolelog('GetTokenOut',outAmount,true)
        return toFixedNumber(outAmount/10**18)
    }catch(err){
        consolelog('GetTokenOut__err',err,true)
    }
}

export const GetRedeemLength =  async(account)=>{
    const web3 = await useWeb3()
    try{
        let contract = await useXcontract()
        let RedeemLength  = await contract.methods.getUserRedeemsLength(account).call({ from: web3.utils.toChecksumAddress(account)})
        return RedeemLength
    }catch(err){
        consolelog('GetRedeemLength___err',err,true)
    }
}

export const GetRedeemInfo =  async(account,index)=>{
    const web3 = await useWeb3()
    try{
        let contract = await useXcontract()
        let RedeemInfo  = await contract.methods.getUserRedeem(account,index).call({ from: web3.utils.toChecksumAddress(account)})
        return RedeemInfo
    }catch(err){
        consolelog('GetRedeemLength___err',err,true)
    }  
}

export const FinalRedeem =  async(account,index)=>{
    const web3 = await useWeb3()
    try{
        let contract = await useXcontract()
        let params = [index]
        const {gasLimit,gasPrice}  = await  EstGas(params,XtokenABI,getXAddress(),'finalizeRedeem',account)
        let RedeemInfo  = await contract.methods.finalizeRedeem(index).send({ from: web3.utils.toChecksumAddress(account),gasLimit:gasLimit,gasPrice:gasPrice})
        return RedeemInfo
    }catch(err){
        consolelog('FinalReem___err',err,true)
    }  
}

export const CancelRedeem =  async(account,index)=>{
    const web3 = await useWeb3()
    try{
        let contract = await useXcontract()
        let params = [index]
        const {gasLimit,gasPrice}  = await  EstGas(params,XtokenABI,getXAddress(),'cancelRedeem',account)
        let cancelRedeemInfo  = await contract.methods.cancelRedeem(index).send({ from: web3.utils.toChecksumAddress(account),gasLimit:gasLimit,gasPrice:gasPrice})
        return cancelRedeemInfo
    }catch(err){
        consolelog('CancelReem___err',err,true)
    }  
}

export const Allocate =  async(usageAddress,amount,usageData,account)=>{
    const web3 = await useWeb3()
    try{
        let contract = await useXcontract()
        let params = [web3.utils.toChecksumAddress(usageAddress),amount,usageData]
        const {gasLimit,gasPrice}  = await  EstGas(params,XtokenABI,getXAddress(),'allocate',account)
        let allocate  = await contract.methods.allocate(web3.utils.toChecksumAddress(usageAddress),amount,usageData).send({ from: web3.utils.toChecksumAddress(account),gasLimit:gasLimit,gasPrice:gasPrice})
        return allocate
    }catch(err){
        consolelog('Allocate__err',err,true)
    }
}

export const Deallocate =  async(usageAddress,amount,usageData,account)=>{
    const web3 = await useWeb3()
    try{
        let contract = await useXcontract()
        let params = [web3.utils.toChecksumAddress(usageAddress),amount,usageData]
        const {gasLimit,gasPrice}  = await  EstGas(params,XtokenABI,getXAddress(),'deallocate',account)
        let Deallocate  = await contract.methods.deallocate(web3.utils.toChecksumAddress(usageAddress),amount,usageData).send({ from: web3.utils.toChecksumAddress(account),gasLimit:gasLimit,gasPrice:gasPrice})
        return Deallocate
    }catch(err){
        consolelog('Allocate__err',err,true)
    }
}

export const ApproveUsage = async(usageAddress,amount,account)=>{
    const web3 = await useWeb3()
    try{
        let contract = await useXcontract()
        let params = [web3.utils.toChecksumAddress(usageAddress),amount]
        const {gasLimit,gasPrice}  = await  EstGas(params,XtokenABI,getXAddress(),'approveUsage',account)
        let approveUsage  = await contract.methods.approveUsage(web3.utils.toChecksumAddress(usageAddress),amount).send({ from: web3.utils.toChecksumAddress(account),gasLimit:gasLimit,gasPrice:gasPrice})
        return approveUsage
    }catch(err){
        consolelog('Allocate__err',err,true)
    }
}