import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import cascading from "../assests/css/dbrequirestyle.module.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FaRegQuestionCircle } from "react-icons/fa";
import { TiInfo } from "react-icons/ti";
import Switch from "react-switch";
import coinimg from "../assests/images/bnb.png";

import metaicon from "../assests/images/metamask.png";
import walleticon from "../assests/images/walletconnect.png";

import { IoMdClose } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa";
import { CHAINS } from "../config/env";
import { GetChainIndex } from "../hooks/useWeb3";
export default function DbRequiremodal(props) {
  // const [dbrequire, setDbrequire] = useState(true);

  const { userData, datas } = props.objData;

  const navigate = useNavigate();

  useEffect(() => {
    console.log("props_", props);
  }, []);

  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal
        centered
        show={props.show}
        onHide={props.onHide}
        className={`${cascading.modalstyle}`}
        backdrop="static"
      >
        <Modal.Header
          className={`${cascading.modalclr} ${cascading.modalheader}`}
        >
          <h4 className={`${cascading.modaltitle}`}>
            {" "}
            {datas?.stakingToken?.symbol} required
          </h4>
          <IoMdClose
            className={`${cascading.closebtn}`}
            onClick={props.onHide}
          />
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          {/* <FaArrowUp  fill='#fff' fontSize={40}/> */}
          <p className={`${cascading.greentext}`}>
            Insufficient {datas?.stakingToken?.symbol} balance
          </p>
          <p className={`${cascading.whitetext} mb-1`}>
            You'll need {datas?.stakingToken?.symbol} to stake this pool!
          </p>
          <p className={`${cascading.whitetext}`}>
            Buy some {datas?.stakingToken?.symbol}, or make sure your{" "}
            {datas?.stakingToken?.symbol} isn't in another pool or LP.
          </p>
          <div className="text-center py-4">
            <button
              className={`btn ${cascading.connectwalletbtn}`}
              type="button"
              onClick={() => {
                navigate("/swap");
              }}
            >
              Buy {datas?.stakingToken?.symbol}
            </button>
          </div>
          <button
            className={`${cascading.declabel}`}
            onClick={() => {
              window.open("https://www.yieldwatch.net/", "_blank");
            }}
            target="_blank"
          >
            Locate Assets
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
