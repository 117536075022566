

// import constant
import {
    GAS_PRICE
} from '../constants';

const initialValue = {
    gasPrice: {}
}

//  console.log("initialValue----->>>",initialValue);
const gasFeesettings = (state = initialValue, action) => {

    switch (action.type) {
        case GAS_PRICE:
            return {
                ...state,
                gasPrice: action.payload
            };
   
        default:
            return state;
    }
}

export default gasFeesettings;