import React, { useState, useRef,useEffect } from "react";
import { FaPlus, FaMinus } from "react-icons/fa6";

import Header from "../../common/header";
import Footer from "../../common/footer";

import cascading from "../../assests/css/Prediction.module.css";
import { AddLotteryvalidation } from "../../hooks/useValidation";
import { isEmpty } from "../../lib/isEmpty";
import toast from "react-hot-toast";
import { iconTheme, position, style } from "../../hooks/useToast";
import { StartLottery, StartLottry, ViewAllLottery, ViewCurrentLottery } from "../../hooks/useLottery";



// Import Swiper React components



// import required modules


// Install Swiper modules

const Addlottery = () => {

  const [isConnectWallet, setIsConnectWallet] = useState(true);
  const [count, setCount] = useState(1)

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Treasury,setTreasury] = useState("");
  const [accountInfo,setaccountInfo] = useState(sessionStorage.getItem("accountInfo"))
  const [reward1,setReward1] = useState("");
  const [reward2,setReward2] = useState("");
  const [reward3,setReward3] = useState("");
  const [reward4,setReward4] = useState("");
  const [reward5,setReward5] = useState("");
  const [reward6,setReward6] = useState("");
  const [endtime,setEndtime] = useState("")
  const [discount,setDiscount] = useState(0)
  const[ticketPrice,setticketPice] = useState("")
  const [fee,setFee] = useState(0)
  const [ValidateError,setValidateError] = useState({})
  const [allrecords,setrecords] = useState([])
  const [addButton,setaddButton] =useState()


useEffect(() =>{
  AllLottery()
},[])


  const AllLottery = async() =>{

    var alllottey = await ViewAllLottery();
    
    var viewLotteryId = await ViewCurrentLottery(alllottey.length);
    var enddate = new Date(viewLotteryId.endTime * 1000); 
    console.log("alllottey>>>>>",viewLotteryId);
    var lotteryStatus = viewLotteryId?.status == 0 && new Date(enddate * 1000) > new Date()
//    if(lotteryStatus == false){
// setaddButton(true)
//     }else{
//       setaddButton(false)

//     }
    console.log("alllottey>>>>>",lotteryStatus);

  

}
  const inputChange = async (e) => {
    console.log("eeeee",e.target);

  const decimalPattern =/^\d+$/;
  if (e.target.value == '' || decimalPattern.test(e.target.value)) {
    setticketPice(e.target.value)
  }
}


const endtimeinputChange = async (e) => {
  console.log("eeeee",e.target);

const decimalPattern =/^\d+$/;
if (e.target.value == '' || decimalPattern.test(e.target.value)) {
  setEndtime(e.target.value)
}
}
const reward1inputChange = async (e) => {
  console.log("eeeee",e.target);

const decimalPattern =/^\d+$/;
if (e.target.value == '' || decimalPattern.test(e.target.value)) {
  setReward1(e.target.value)
}
}
const reward2inputChange = async (e) => {
  console.log("eeeee",e.target);

const decimalPattern =/^\d+$/;
if (e.target.value == '' || decimalPattern.test(e.target.value)) {
  setReward2(e.target.value)
}
}
const reward3inputChange = async (e) => {
  console.log("eeeee",e.target);

const decimalPattern =/^\d+$/;
if (e.target.value == '' || decimalPattern.test(e.target.value)) {
  setReward3(e.target.value)
}
}

const reward4inputChange = async (e) => {
  console.log("eeeee",e.target);

const decimalPattern =/^\d+$/;
if (e.target.value == '' || decimalPattern.test(e.target.value)) {
  setReward4(e.target.value)
}
}
const reward5inputChange = async (e) => {
  console.log("eeeee",e.target);

const decimalPattern =/^\d+$/;
if (e.target.value == '' || decimalPattern.test(e.target.value)) {
  setReward5(e.target.value)
}
}
const reward6inputChange = async (e) => {
  console.log("eeeee",e.target);

const decimalPattern =/^\d+$/;
if (e.target.value == '' || decimalPattern.test(e.target.value)) {
  setReward6(e.target.value)
}
}
  const onSubmit = async() =>{

    let currDate = new Date().getTime() / 1000;
console.log("currDate", currDate,endtime,currDate < endtime);
    if( currDate > parseInt(endtime)){
      await toast.error("End Time should be Future date",
        {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
        })
        return false;
    }else{
      var data = {
        endtime : endtime,
        ticketPice:ticketPrice,
        reward1:reward1,
        reward2:reward2,
        reward3:reward3,
        reward4:reward4,
        reward5:reward5,
        reward6:reward6,
  
      }
  
      var   errors  = await AddLotteryvalidation(data);
      if (
        parseInt(reward1) +
          parseInt(reward2) +
          parseInt(reward3) +
          parseInt(reward4) +
          parseInt(reward5) +
          parseInt(reward6) >
          10000 ||
        parseInt(reward1) +
          parseInt(reward2) +
          parseInt(reward3) +
          parseInt(reward4) +
          parseInt(reward5) +
          parseInt(reward6) <
          10000
      ) {
        await toast.error("Total spilt up must be 10000...!!!",
        {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
        })
        return false;
      }else if(isEmpty(errors)){
  
        var arra = [];
        arra.push(parseInt(reward1));
        arra.push(parseInt(reward2));
        arra.push(parseInt(reward3));
        arra.push(parseInt(reward4));
        arra.push(parseInt(reward5));
        arra.push(parseInt(reward6));
        let convertamout= ticketPrice * 10 ** 18;
        const result =  await StartLottery(endtime,convertamout,discount,arra,fee,accountInfo);
      }else{
      setValidateError(errors)
      // return false
  
      }
    }
  
  }


  return (
    <div>
     
      <Header />
      <div className={`${cascading.innerpages} ${cascading.predictionPage} ${cascading.lotteryPage}`}>
        <div className={`container`}>
          <div className={`row flex-column ${cascading.topSection}`}>
            <h3 className={`text-center ${cascading.pageTitle}`}>Add Lottery</h3>
          <div className="mt-4">

          <div
            className={`row justify-content-center ${cascading.swapcardrow}`}
          >
            <div className={`col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 `}>
              <div className={` ${cascading.cardstylelottery} dfdsfsdf`}>
               
                <div className={`${cascading.midrow}`}>
                
                  <div className={`${cascading.inputsec} mb-3`}>
                    <div className={`${cascading.balancerow}`}>
                      <p className={`${cascading.inputlabel}`}>
                        End Time 
                      </p>
                        
                    </div>

                    <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                      <div>
                        <input
                          type="text"
                          className={`from-control`}
                          id="endtime"
                          aria-describedby="endtime"
                          placeholder="End Time (in timestamp)"
                          value={endtime} 
                          onChange={endtimeinputChange}
                         
                        />
                      </div>
             
                    </div>
                  
                  </div>
                  {ValidateError.endtime && (
                      <span className="text-danger">
                        {ValidateError.endtime}
                      </span>
                    )}

                  <div className={`${cascading.inputsec} mb-3`}>
                    <div className={`${cascading.balancerow}`}>
                      <p className={`${cascading.inputlabel}`}>
                        Ticket Prize 
                      </p>
                        
                    </div>

                    <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                      <div>
                        <input
                          type="text"
                          className={`from-control`}
                          id="ticketprize"
                           name="ticketprize"
                          aria-describedby="ticketprize"
                          placeholder="Ticket Prize"
                          value={ticketPrice} 
                          onChange={inputChange}
 
                          // onChange={(e) => { setticketPice(e.target.value) }}
                         
                        />
                      </div>
             
                    </div>
                   
                  </div>
                  {console.log("ValidateError.ticketPrice",ValidateError.ticketPrice)}
                  {ValidateError && ValidateError.ticketPrice && (
                      <span className="text-danger">
                        {ValidateError.ticketPrice}
                      </span>
                    )}

               
                   <p className={`${cascading.themetext}`}>Prizes Rewards</p>
                   <div className={`${cascading.inputsec} mb-3`}>
                    <div className={`${cascading.balancerow}`}>
                      <p className={`${cascading.inputlabel}`}>
                        First Prize in % 
                      </p>
                        
                    </div>

                    <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                      <div>
                        <input
                          type="text"
                          className={`from-control`}
                          id="first"
                          aria-describedby="ticketprize"
                          placeholder="First Prize in %"
                          value={reward1} 
                          onChange={reward1inputChange}
                         
                        />
                      </div>
                       </div>
                      
                       
                  </div>
                  {ValidateError.reward1 && (
                      <span className="text-danger">
                        {ValidateError.reward1}
                      </span>
                    )}
                  <div className={`${cascading.inputsec} mb-3`}>
                    <div className={`${cascading.balancerow}`}>
                      <p className={`${cascading.inputlabel}`}>
                      Second Prize in % 
                      </p>
                        
                    </div>

                    <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                      <div>
                        <input
                          type="text"
                          className={`from-control`}
                          id="second"
                          aria-describedby="ticketprize"
                          placeholder="second Prize in %"
                          value={reward2} 
                          onChange={reward2inputChange}
                         
                        />
                      </div>
                       </div>
                      
                       
                  </div>
                  {ValidateError.reward2 && (
                      <span className="text-danger">
                        {ValidateError.reward2}
                      </span>
                    )}
                  <div className={`${cascading.inputsec} mb-3`}>
                    <div className={`${cascading.balancerow}`}>
                      <p className={`${cascading.inputlabel}`}>
                        Third Prize in % 
                      </p>
                        
                    </div>

                    <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                      <div>
                        <input
                          type="text"
                          className={`from-control`}
                          id="third"
                          aria-describedby="ticketprize"
                          placeholder="Third Prize in %"
                          value={reward3} 
                          onChange={reward3inputChange}
                        />
                      </div>
                       </div>
                      
                       
                  </div>
                  {ValidateError.reward3 && (
                      <span className="text-danger">
                        {ValidateError.reward3}
                      </span>
                    )}
                  <div className={`${cascading.inputsec} mb-3`}>
                    <div className={`${cascading.balancerow}`}>
                      <p className={`${cascading.inputlabel}`}>
                        Fourth Prize in %
                      </p>
                        
                    </div>

                    <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                      <div>
                        <input
                          type="text"
                          className={`from-control`}
                          id="fourth"
                          aria-describedby="ticketprize"
                          placeholder="Fourth Prize in %"
                          value={reward4} 
                          onChange={reward4inputChange}
                         
                        />
                      </div>
                       </div>
                    
                       
                  </div>
                  {ValidateError.reward4 && (
                      <span className="text-danger">
                        {ValidateError.reward4}
                      </span>
                    )}
                  <div className={`${cascading.inputsec} mb-3`}>
                    <div className={`${cascading.balancerow}`}>
                      <p className={`${cascading.inputlabel}`}>
                        Fifth Prize in %
                      </p>
                        
                    </div>

                    <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                      <div>
                        <input
                          type="text"
                          className={`from-control`}
                          id="Fifth"
                          aria-describedby="ticketprize"
                          placeholder="Fifth Prize in %"
                          value={reward5} 
                          onChange={reward5inputChange}
                        />
                      </div>
                       </div>
                      
                       
                  </div>
                  {ValidateError.reward5 && (
                      <span className="text-danger">
                        {ValidateError.reward5}
                      </span>
                    )}
                  <div className={`${cascading.inputsec} mb-3`}>
                    <div className={`${cascading.balancerow}`}>
                      <p className={`${cascading.inputlabel}`}>
                        Sixth Prize in % 
                      </p>
                        
                    </div>

                    <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                      <div>
                        <input
                          type="text"
                          className={`from-control`}
                          id="sixth"
                          aria-describedby="ticketprize"
                          placeholder="Sixth Prize in %"
                          value={reward6} 
                          onChange={reward6inputChange}

                         
                        />
                      </div>
                       </div>
                     
                       
                  </div>
                  {ValidateError.reward6 && (
                      <span className="text-danger">
                        {ValidateError.reward6}
                      </span>
                    )}
                 
                  {/* {[...Array(count)].map(()=>
{
   return <>
                  <div className={`${cascading.inputsec} mb-3`}>
                    <div className={`${cascading.balancerow}`}>
                      <p className={`${cascading.inputlabel}`}>
                      Prize % 
                      </p>
                        
                    </div>

                    <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                      <div>
                        <input
                          type="text"
                          className={`from-control`}
                        //   id="firstprize"
                          aria-describedby="firstprize"
                          placeholder="Prize %"
                         
                        />
                      </div>

                      <div>
                        </div>
             
                    </div>

                   
                  </div>
                  </>
})}

                  <div className="d-flex align-items-center justify-content-end mb-4">
                  <button
                                                    className={`btn ${cascading.iconbtnnew} me-2`}
                                                    onClick={() => {
                                                       setCount(count + 1)
                                                    }}> <FaPlus /> </button>

<button
                                                    className={`btn ${cascading.iconbtnnew}`}
                                                    onClick={() => {
                                                       setCount(count - 1)
                                                    }}> <FaMinus /> </button>
                </div>  */}
                  <button
                      className={`btn ${cascading.formbtn}`}
                      disabled={addButton}
                   onClick={() => onSubmit()} >
                     Add
                    </button>
                </div>

              
             



              
              
              </div>

          
            </div>
          </div>
       
          </div>
          </div>
        </div>

      
      </div>

      <Footer />

    </div>
  );
};

export default Addlottery;
