import React, { useEffect, useState } from "react";
import { Modal, Button, Tab, Tabs } from 'react-bootstrap';

import cascading from "../assests/css/Lottery.module.css";
import Header from "../common/header";
import Footer from "../common/footer";
import moment from "moment";
import Buyticketmodal from "../modals/buyticketmodal";

import play1 from "../../src/assests/images/play1.png"
import play2 from "../../src/assests/images/play2.png"
import Countdown, { zeroPad } from "react-countdown";

import { FaChevronDown } from "react-icons/fa6";
import { FaChevronUp, FaArrowLeft, FaArrowRight  } from "react-icons/fa";
import Viewticketmodal from "../modals/viewticketmodal";
import Editticketmodal from "../modals/editticketmodal";
import { UseviewUserInfoForLotteryId, ViewCurrentLottery, getLotteryInfo } from "../hooks/useLottery";
import { LotterytokenSymbol } from "../config/lottery";


    {/* <p className={`${cascading.timer} text-center `}>
                    <span className={`${cascading.timerValue}`}>7</span>
                    <span className={`${cascading.timerText}`}>h</span>
                    <span className={`${cascading.timerValue} ms-2`}>28</span>
                    <span className={`${cascading.timerText} ms-1 me-2`}>
                      m
                    </span>
                    until the draw
                  </p> */}


const renderer = ({ days, hours, minutes, seconds }) => {
  var currDate = new Date();
  return (
    <div className={`${cascading.timer} text-center mt-0`}>
      <span><span className={`${cascading.timerValue}`}>{zeroPad(days)}</span>
        <span className={`${cascading.timerText} mx-1`}>Days</span></span>
      {/* <span className="timer_dots">:</span> */}
      <span>
        <span className={`${cascading.timerValue}`}>{zeroPad(hours)}</span>
        <span className={`${cascading.timerText} mx-1`}>Hours</span>
      </span>
      {/* <span className="timer_dots">:</span> */}
      <span>
        <span className={`${cascading.timerValue}`}>{zeroPad(minutes)}</span>
        <span className={`${cascading.timerText} mx-1`}>Mins</span>
      </span>
      <span>
        <span className={`${cascading.timerValue}`}>{zeroPad(seconds)}</span>
        <span className={`${cascading.timerText} ms-1`}>Seconds</span>
      </span>
      {/* <span className="timer_dots">:</span> */}
      {/* <span><span className="timer_time">{zeroPad(seconds)}</span><span className="timer_label">Secs</span></span> */}
    </div>
  );
};


const Lottery = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [iswalletConnect, setIswalletconnect] = useState(false);
  const [buyticket, setBuyticket] = useState(true);
  const [buyticketmodal, setBuyticketmodal] = useState(false);
  const [editticketmodal, setEditticketmodal] = useState(false);
  const [viewticketmodal, setViewticketmodal] = useState(false);
  const [iswinner, setIswinner] = useState(false);
 const [Endlotterytime, SetEndlotteryTime] = useState(null);
  const [StartlotteryTime, SetStartlotteryTime] = useState(new Date());
  const [lotteryAmt, Set_lotteryAmt] = useState("");
  const [timerStart, setTimerStart] = useState(0);
  const [lotteryPrice, Set_lotteryPrice] = useState("");
  const [lotteryPrizeAmount, SetlotteryPrizeAmount] = useState(0);
  const [currentLottery, SetcurrentLottery] = useState("");
  const [userTicketCount, SetuserTicketCount] = useState("");
  const [rewardsBreakdown, SetrewardsBreakdown] = useState("");

  const [historyId, SethistoryId] = useState("");
  const [hisEndlotterytime, SethisEndlotteryTime] = useState(new Date());
  const [hisStartlotteryTime, SethisStartlotteryTime] = useState();
  const [hislotteryPrice, Set_hislotteryPrice] = useState("");
  const [hislotteryPrizeAmount, SethislotteryPrizeAmount] = useState(0);
  const [hisrewardsBreakdown, SethisrewardsBreakdown] = useState("");
  const [hisuserTicketCount, SethisuserTicketCount] = useState("");
  const [incss, setincss] = useState("none");
  const [outcss, setoutcss] = useState("block");

  const [TicStartlotteryTime, SetTicStartlotteryTime] = useState(
    new Date()
  );
  const [TicEndlotteryTime, SetTicEndlotteryTime] = useState();
  const [TiclotteryPrice, Set_TiclotteryPrice] = useState("");
  const [TiclotteryPrizeAmount, SetTiclotteryPrizeAmount] = useState("");
  const [TicrewardsBreakdown, SetTicrewardsBreakdown] = useState("");
  const [TicuserTicketCount, SetTicuserTicketCount] = useState("");
  const [TicfinalNumber, SetTicfinalNumber] = useState([]);
  const [Ticid, SetTicid] = useState("");
  const [claim, setclaim] = useState();
  const [loader, setloader] = useState(false);
  const [allHist, SetallHist] = useState([]);
  const [provider, setprovider] = useState();
  const [address, setaddress] = useState();
  const [finalNumber, SetfinalNumber] = useState([]);
  const [Allowance, setAllowance] = useState();

  const [showCont,setShowCont] = useState(false);
  const [lotterystatus,setlotterystatus] = useState()
  
  
  const [accountInfo,setaccountInfo] = useState(sessionStorage.getItem("accountInfo"))


  //
  const [ticketNumber,setticketNumber] = useState([]);
  const [lotteryamount, setLotteryAmount] = useState("");
useEffect(()=>{
  getLotteryDetails()

},[accountInfo])

  const getLotteryDetails = async() =>{
    var lotteryinfo = await getLotteryInfo(accountInfo);
    var amountPrize = parseFloat(lotteryinfo?.viewcurrentLottery?.amountCollectedInCake) / 10 ** 18;
    var finalPrizeAmount = amountPrize;
  
    var startdate = new Date(lotteryinfo?.viewcurrentLottery.startTime * 1000);
    console.log("startdate",lotteryinfo);

    var enddate = new Date(lotteryinfo?.viewcurrentLottery.endTime * 1000); 
    var getUserLottery = lotteryinfo?.getUserLottery;
    SetcurrentLottery(Number(lotteryinfo?.currentLotteryId))
    SetEndlotteryTime(Date.parse(new Date(enddate)));
    SetStartlotteryTime(startdate);
    setTimerStart(1);
        Set_lotteryPrice(lotteryinfo?.viewcurrentLottery?.priceTicketInCake / 10 ** 18);
        SetlotteryPrizeAmount(finalPrizeAmount);
        SetuserTicketCount(lotteryinfo?.UserInfoForLotteryId[3]);
        SetrewardsBreakdown(lotteryinfo?.viewcurrentLottery?.rewardsBreakdown);
        SetfinalNumber(lotteryinfo?.viewcurrentLottery?.finalNumber)
        var lotteryStatus = lotteryinfo?.viewcurrentLottery?.status == 0 && new Date(enddate * 1000) > new Date()
        if(lotteryStatus == false){
          setlotterystatus(true)
        }else{
          setlotterystatus(false)

        }
        const UniqueLott = {};
     
        getUserLottery.forEach(function (x) {
         
          if (x == lotteryinfo?.currentLotteryId){
            UniqueLott[x] = (UniqueLott[x] || 0) + 1;
          }
            


          
        });

        var histArray = [];
        console.log("property_Data", UniqueLott)
        for (const property in UniqueLott) {
          var obj = {};
          var ticketid = property;
          var count = UniqueLott[property];
          console.log("count_Data", count)
          var hisTick = await ViewCurrentLottery(ticketid);
          var enddate = new Date(hisTick.endTime * 1000);

          obj = { ticketid: ticketid, count: count, enddate: enddate };

          histArray.push(obj);
          SetallHist(histArray);
        }

     
        await historyChange("id", lotteryinfo?.currentLotteryId);
  }


  const enableOut =async(id)=> {
    var lotteryinfo = await UseviewUserInfoForLotteryId(accountInfo,id);
    var viewLotteryId = await ViewCurrentLottery(id);
    var startdate = new Date(viewLotteryId.startTime * 1000);
    var enddate = new Date(viewLotteryId.endTime * 1000);
    var amountPrize = viewLotteryId.amountCollectedInCake / 10 ** 18;
    var finalPrizeAmount = amountPrize;
      SetTicStartlotteryTime(startdate);
      SetTicEndlotteryTime(enddate);
      Set_TiclotteryPrice(viewLotteryId.priceTicketInCake / 10 ** 18 );
      SetTiclotteryPrizeAmount(finalPrizeAmount);
      SetTicrewardsBreakdown(viewLotteryId.rewardsBreakdown);
      SetTicuserTicketCount(lotteryinfo[3]);
      var digits = viewLotteryId.finalNumber.toString().split("");
      var realDigits = digits.map(Number);
      
      SetTicfinalNumber(realDigits);
      SetTicid(id)

    }
  
console.log("historyId_data", historyId)

  const historyChange = async (type, e) => {
    console.log("historyChange", type, e);
    try {

      // currentLottery
      var currentLotteryHis = parseInt(currentLottery) - 1;
      var id = currentLotteryHis;
      console.log("currentLotterasdy", id)
      if (type == "curr") {
        var id =
          e.target.value &&
            e.target.value <= currentLotteryHis &&
            e.target.value != 0 &&
            e.target.value != ""
            ? e.target.value
            : currentLotteryHis;
      } else if (type == "next") {
        var next = parseInt(historyId) + 1;
        var id = next
        // next <= currentLotteryHis && next != 0 && next != ""
        //   ? next
        //   : currentLotteryHis;
      } else if (type == "prev") {
        console.log("prev",Ticid, type, historyId);
        var next = historyId != "" ?  parseInt(historyId) - 1 : parseInt(currentLottery) - 1;
        var id = next
        // next <= currentLotteryHis && next != 0 && next != ""
        //   ? next
        //   : currentLotteryHis;
      } else if (type == "id") {
        var id = 1;
      }
      console.log("hidid", id, currentLottery, typeof currentLottery);
      //  if(id != 0 && (id <= Number(currentLottery))) {
      if (id != 0) {
        console.log("conditiondaatat", id,currentLottery, currentLottery != "string" && id <= currentLottery)
        if (currentLottery != "string" && id <= currentLottery) {
          SethistoryId(id);
          var viewLotteryDetails = await UseviewUserInfoForLotteryId(accountInfo, id);
          var viewLotteryId = await ViewCurrentLottery(id);
          var startdate = new Date(viewLotteryId.startTime * 1000);
          var enddate = new Date(viewLotteryId.endTime * 1000);
          var lotteryStatus =viewLotteryId?.status == 0 && new Date(enddate * 1000) > new Date()
if(lotteryStatus == false){
          setlotterystatus(true)
        }else{
          setlotterystatus(false)

        }
          var amountPrize =
            viewLotteryId.amountCollectedInCake / 10 ** 18;

          var minusTruesaryFees = (amountPrize * 0) / 100;

          var finalPrizeAmount = amountPrize;

          SethisStartlotteryTime(startdate);
          SethisEndlotteryTime(enddate);
          SetEndlotteryTime(enddate);
          Set_hislotteryPrice(
            viewLotteryId.priceTicketInCake / 10 ** 18
          );
          SethislotteryPrizeAmount(finalPrizeAmount);
          SetlotteryPrizeAmount(finalPrizeAmount);
          SethisuserTicketCount(viewLotteryDetails[3]);
          SethisrewardsBreakdown(viewLotteryId.rewardsBreakdown);
          SetrewardsBreakdown(viewLotteryId.rewardsBreakdown);

          var digits = viewLotteryId.finalNumber.toString().split("");
          var realDigits = digits.map(Number);

          SetfinalNumber(realDigits);
        } else if (currentLottery == "string") {
          SethistoryId(id);
          var viewLotteryDetails = await UseviewUserInfoForLotteryId(accountInfo, id);
          var viewLotteryId = await ViewCurrentLottery(id);
          var startdate = new Date(viewLotteryId.startTime * 1000);
          var enddate = new Date(viewLotteryId.endTime * 1000);

          var amountPrize =
            viewLotteryId.amountCollectedInCake / 10 ** 18;

          var minusTruesaryFees = (amountPrize * 0) / 100;

          var finalPrizeAmount = amountPrize;

          SethisStartlotteryTime(startdate);
          SethisEndlotteryTime(enddate);
          Set_hislotteryPrice(
            viewLotteryId.priceTicketInCake / 10 ** 18
          );
          SethislotteryPrizeAmount(finalPrizeAmount);
          SethisuserTicketCount(viewLotteryDetails[3]);
          SethisrewardsBreakdown(viewLotteryId.rewardsBreakdown);
          var digits = viewLotteryId.finalNumber.toString().split("");
          var realDigits = digits.map(Number);

          SetfinalNumber(realDigits);
        }
      }

      //  }



    } catch (err) { }
  }
  return (
    <div>
      <Header />
      <div className={`${cascading.lotteryInner}`}>
     

        <div className={`${cascading.ticketSec} `}>
          <div className={`container`}>
            <div className={`row justify-content-center`}>
              <div className={`col-12 col-md-9 col-lg-8 col-xl-7 col-xxl-6`}>
                <div className={`${cascading.ticketInner}`}>
                  <h4 className={`${cascading.title} text-center`}>
                    Get your tickets now!
                    {console.log("Endlotterytime",timerStart)}
                  </h4>
                
                  {Endlotterytime && timerStart == 1 &&
                  <div className={`${cascading.felxcolmob} d-flex align-items-center justify-content-center`}>
                  
                            <Countdown className="mt-0"
                              date={parseInt(Endlotterytime)}
                              renderer={renderer}
                            >
                            </Countdown>
<p className="mb-0 mt-2 ms-sm-2">  until the draw</p> 

</div>

}

{/* <Countdown date={Date.now() + 1000000000}  renderer={renderer}>
                            </Countdown> */}
                  {/* <p className={`${cascading.timer} text-center `}>
                    <span className={`${cascading.timerValue}`}>7</span>
                    <span className={`${cascading.timerText}`}>h</span>
                    <span className={`${cascading.timerValue} ms-2`}>28</span>
                    <span className={`${cascading.timerText} ms-1 me-2`}>
                      m
                    </span>
                    until the draw
                  </p> */}
<div></div>                  
                  <div className={`${cascading.ticketCard} `}>
                    <div
                      className={`${cascading.ticketCardTop} d-flex align-items-center justify-content-between`}
                    >
                      <p className={`${cascading.cardTitle} mb-0`}>Next Draw</p>
                      <p className={` ${cascading.cardSubTitle} mb-0`}>
                        #{currentLottery} | Draw: {moment(Endlotterytime).format('LLLL')}
                      </p>
                    </div>
                    <div className={`${cascading.content}`}>
                      <div className={`row mx-auto`}>
                        <div
                          className={`col-5 col-md-4 col-lg-3 col-xl-4 col-xxl-3 ps-0`}
                        >
                          <p className={`${cascading.cardTitle} mb-0`}>
                            Prize Pot
                          </p>
                        </div>{" "}
                        <div
                          className={`col-7 col-md-8 col-lg-9 col-xl-8 col-xxl-9`}
                        >
                          <div>
                            <h3 className={`${cascading.priceValue} mb-0`}>
                              {lotteryPrizeAmount.toFixed(4)} {LotterytokenSymbol}
                            </h3>
                            {/* <p className={`${cascading.uoValue}`}>21,881 UO</p> */}
                          </div>
                        </div>
                      </div>{" "}
                      <div className={`row mx-auto`}>
                        <div
                          className={`col-5 col-md-4 col-lg-3 col-xl-4 col-xxl-3 ps-0`}
                        >
                          <p className={`${cascading.cardTitle} mb-0`}>
                            Your tickets
                          </p>
                        </div>{" "}
                        <div
                          className={`col-7 col-md-8 col-lg-9 col-xl-8 col-xxl-9 d-flex align-items-start`}
                        >
                          {console.log("lotterystatus",lotterystatus)}
                          {buyticket?
                          <div className={`${cascading.ticketviewsec}`}>
                          <p className="mb-0">You have {userTicketCount} tickets</p>
                          {parseInt (userTicketCount) > 0 ? 
                          <button className={`${cascading.viewtktbtn}`} onClick={()=>{setViewticketmodal(true);}}>View your tickets</button> : 
                          <button className={`${cascading.viewtktbtn}`} disabled={true}>View your tickets</button>


                          }
                          <button  className={`${cascading.buyTickets} mt-3`} disabled={lotterystatus} onClick={()=>{setBuyticketmodal(true)}}>
                          Buy Tickets
                        </button>
                          </div>
                          :
                          <button disabled={lotterystatus} className={`${cascading.buyTickets}`} onClick={()=>{setBuyticketmodal(true)}}>
                          Buy Tickets
                        </button>
                          }
                        
                        </div>
                      </div>
                    </div>
                    <div className={`${cascading.bottom} d-flex flex-column`}>
                      {showDetails && (
                        <div className={`${cascading.showDetails}`}>
                          <p>
                            Match the winning number in the same order to share
                            prizes. Current prizes up for grabs:
                          </p>
                          <div className="row">
                           {console.log("rewardsBreakdown",rewardsBreakdown,lotteryPrizeAmount)}
                            <div className="col-12 col-md-6 col-lg-3 mb-1">
                              <p className={`${cascading.match_text_1} mb-1`}>Match first 1</p>
                              <p className={`${cascading.match_text_2} mb-1`}>{(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[5]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                        {LotterytokenSymbol}</p>
                              {/* <p className={`${cascading.match_text_3}`}>~$1,004</p> */}

                              </div>

                               <div className="col-12 col-md-6 col-lg-3 mb-1">
                              <p className={`${cascading.match_text_1} mb-1`}>Match first 2</p>
                              <p className={`${cascading.match_text_2} mb-1`}>{(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[4]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                         {LotterytokenSymbol} </p>
                              {/* <p className={`${cascading.match_text_3}`}>~$3,004</p> */}

                              </div>

                              <div className="col-12 col-md-6 col-lg-3 mb-1">
                              <p className={`${cascading.match_text_1} mb-1`}>Match first 3</p>
                              <p className={`${cascading.match_text_2} mb-1`}>{(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[3]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                        {LotterytokenSymbol} </p>
                              {/* <p className={`${cascading.match_text_3}`}>~$4,004</p> */}

                              </div>


                              <div className="col-12 col-md-6 col-lg-3 mb-1">
                              <p className={`${cascading.match_text_1} mb-1`}>Match first 4</p>
                              <p className={`${cascading.match_text_2} mb-1`}>{(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[2]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                        {LotterytokenSymbol} </p>
                              {/* <p className={`${cascading.match_text_3}`}>~$5,004</p> */}

                              </div>

                              <div className="col-12 col-md-6 col-lg-3 mb-1">
                              <p className={`${cascading.match_text_1} mb-1`}>Match first 5</p>
                              <p className={`${cascading.match_text_2} mb-1`}>{(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[1]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                        {LotterytokenSymbol} </p>
                              {/* <p className={`${cascading.match_text_3}`}>~$6,004</p> */}

                              </div>

                              <div className="col-12 col-md-6 col-lg-3 mb-1">
                              <p className={`${cascading.match_text_1} mb-1`}>Match all 6</p>
                              <p className={`${cascading.match_text_2} mb-1`}>{(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[0]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                        {LotterytokenSymbol} </p>
                              {/* <p className={`${cascading.match_text_3}`}>~$8,004</p> */}

                              </div>

                          
                            </div>
                        </div>
                      )}
                      <button
                        className={`${cascading.detailsButton} mx-auto`}
                        onClick={() => setShowDetails(!showDetails)}
                      >
                        {showDetails ? (
                          <div className={`d-flex align-items-center`}>
                            <p className={`mb-0`}>Hide</p>
                            <FaChevronUp
                              fontSize={11}
                              className="ms-2 mb-1"
                            />{" "}
                          </div>
                        ) : (
                          <div className={`d-flex align-items-center`}>
                            <p className={`mb-0`}>Details</p>
                            <FaChevronDown
                              fontSize={11}
                              className="ms-2 mb-1"
                            />{" "}
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${cascading.connectWallet} `}>
          <div className={`container`}>
            {iswalletConnect?
               <div className={`row justify-content-center`}>
                {iswinner?
                 <div className={`col-12 col-md-10 col-xl-3`}>
                 <div className={`position-relative`}>
                   <p className={`${cascading.text} mb-0 text-center`}>
                   No prizes to collect...

                   </p>

                   <p className={`${cascading.text} mb-0 text-center`}>
                   Better luck next time!
                 </p>
                 
                 
                 </div>
               </div>
               :
               <div className={`col-12 col-md-10 col-xl-3`}>
               <div className={`position-relative`}>
                 <p className={`${cascading.text} mb-0 text-center`}>
                 Are you a winner?
                 </p>
               
                 <div className={`d-flex justify-content-center`}>
                   <button onClick={()=>{setIswinner(true)}}
                     className={`${cascading.connectButton} mt-3 mx-auto`}
                   >
                     Check Now
                   </button>
                 </div>
               </div>
             </div>
                }
              
             </div>
             :
             <div className={`row justify-content-center`}>
             <div className={`col-12 col-md-10 col-xl-3`}>
               <div className={`position-relative`}>
                 <p className={`${cascading.text} mb-0 text-center`}>
                   Connect your wallet
                 </p>
                 <p className={`${cascading.text} mb-0 text-center`}>
                   to check if you've won!
                 </p>
                 <div className={`d-flex justify-content-center`}>
                   <button
                     className={`${cascading.connectButton} mt-3 mx-auto`}
                   >
                     Connect Wallet
                   </button>
                 </div>
               </div>
             </div>
           </div>
            }
          
          </div>
        </div>

        <div className={`${cascading.ticketSec} ${cascading.finishedRounds} pt-5`}>
          <div className={`container`}>
            <div className={`row justify-content-center`}>
              <div className={`col-12 col-md-9 col-lg-8 col-xl-7 col-xxl-6`}>
                <div>
                  <div>
                  <h4 className={`${cascading.title} ${cascading.titlepurple} text-center`}>
                  Finished Rounds
                  </h4>

                  <Tabs
      defaultActiveKey="allhistory"
      id="uncontrolled-tab-example"
      className={`${cascading.tabstyletabs} mt-5 mb-5`}
    >
      <Tab eventKey="allhistory" title="All History">
        
      <div className={`${cascading.ticketCard} `}>
                    <div
                      className={`${cascading.ticketCardTop} d-flex align-items-center justify-content-between`}
                    >
                      <div>
                      <div className="d-flex align-items-center">
                      <p className={`${cascading.cardTitle} mb-0`}>Round
                      <div className={`${cascading.badge_blue} mb-0 badge ms-2`}>{currentLottery}</div>
                      </p>
                      </div>
                     

                      <p className={`${cascading.match_text_lottery} mt-3 mb-1`}>Drawn {moment(hisEndlotterytime).format('LLLL')}</p>
                      </div>
                      <p className={` ${cascading.cardSubTitle} mb-0 d-flex align-items-center`}>
                      <div className={`${cascading.arrowpagination} ${cascading.disabled}`}>
                      <span className={`${cascading.bartextleft}`}></span>

                        
                          </div>
                          <FaArrowLeft  onClick={() => {historyChange("prev")}} className={`${cascading.arrowpagination}`}/>    
                          <FaArrowRight onClick={() => historyChange("next")}  className={`${cascading.arrowpagination}`}/>     
                          <div className={`${cascading.arrowpagination} ${cascading.disabled}`}>
                        
                          <span className={`${cascading.bartext}`}></span>
                          </div>
                      </p>
                    </div>
                    <div className={`${cascading.content}`}>
 {parseInt(historyId) == parseInt(currentLottery) ? <div className={`${cascading.ribbondiv}`}>
<p className="mb-0">Latest</p>
</div> : <></> }                     

                      <div className={`row mx-auto align-items-center`}>
                        <div
                          className={`col-12 col-sm-5 col-md-4 col-lg-3 col-xl-3 col-xxl-3 ps-0`}
                        >
                          <p className={`${cascading.cardTitle} mb-0 text-center text-sm-start`}>
                           Winning Number
                          </p>
                        </div>{" "}
                        <div
                          className={`col-12 col-sm-7 col-md-8 col-lg-9 col-xl-9 col-xxl-9 mt-4 mt-sm-0`}
                        >
                         <div className="d-flex align-items-center justify-content-end">
                          <div className={`${cascading.lotterydiv} ${cascading.lotteryfirst}`}>
                         <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
                         <p className={`${cascading.lotterynumber}`}>{finalNumber && finalNumber[6] ? finalNumber[6] : "0"}</p>
                         </div>

                         <div className={`${cascading.lotterydiv} ${cascading.lotterysecond}`}>
                         <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
                         <p className={`${cascading.lotterynumber}`}>{finalNumber && finalNumber[5] ? finalNumber[5] : "0"}</p>
                         </div>

                         <div className={`${cascading.lotterydiv} ${cascading.lotterythird}`}>
                         <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
                         <p className={`${cascading.lotterynumber}`}>{finalNumber && finalNumber[4] ? finalNumber[4] : "0"}</p>
                         </div>

                         <div className={`${cascading.lotterydiv} ${cascading.lotteryfourth}`}>
                         <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
                         <p className={`${cascading.lotterynumber}`}>{finalNumber && finalNumber[3] ? finalNumber[3] : "0"}</p>
                         </div>

                         <div className={`${cascading.lotterydiv} ${cascading.lotteryfifth}`}>
                         <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
                         <p className={`${cascading.lotterynumber}`}>{finalNumber&& finalNumber[2] ? finalNumber[2] : "0"}</p>
                         </div>

                         <div className={`${cascading.lotterydiv} ${cascading.lotterysixth}`}>
                         <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
                         <p className={`${cascading.lotterynumber}`}>{finalNumber && finalNumber[1] ? finalNumber[1] : "0"}</p>
                         </div>
                         </div>
                        </div>
                      </div>{" "}
                     
                    </div>
                    <div className={`${cascading.bottom} d-flex flex-column`}>
                      {showDetails && (
                        <div className={`${cascading.showDetails}`}>
                          <p>
                            Match the winning number in the same order to share
                            prizes. Current prizes up for grabs:
                          </p>                 
                          <div className="row">
                            <div className="col-12 col-md-6 col-lg-6 mb-1">
                              <p className={`${cascading.match_text_2} mb-1`}>Prize Pot</p>
                              <p className={`${cascading.match_text_big} mb-1`}>{lotteryPrizeAmount.toFixed(4)} {LotterytokenSymbol}</p>
                              
                              {/* <p className={`${cascading.match_text_3}`}>21,125 {LotterytokenSymbol}</p> */}

                              </div>
                              {/* <div className="col-12 col-md-6 col-lg-6 mb-1">
                              <p className={`${cascading.match_text_2} mb-1`}>Total players this round</p>
                              
                              <p className={`${cascading.match_text_3}`}>300</p>

                              </div> */}
                            </div>
                            <hr className={`${cascading.hrlottery} ${cascading.hrlotterysm} d-none d-lg-block`} />
                          <div className="row">
                            <div className="col-12 col-md-6 col-lg-3 mb-1">
                              <p className={`${cascading.match_text_1} mb-1`}>Match first 1</p>
                              <p className={`${cascading.match_text_2} mb-1`}>{(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[5]) /
                                          10000
                                        ).toFixed(2)}{" "} {LotterytokenSymbol}</p>
                              {/* <p className={`${cascading.match_text_3}`}>~$1,004</p> */}

                              </div>

                               <div className="col-12 col-md-6 col-lg-3 mb-1">
                              <p className={`${cascading.match_text_1} mb-1`}>Match first 2</p>
                              <p className={`${cascading.match_text_2} mb-1`}>{(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[4]) /
                                          10000
                                        ).toFixed(2)}{" "} {LotterytokenSymbol}</p>
                              {/* <p className={`${cascading.match_text_3}`}>~$3,004</p> */}

                              </div>

                              <div className="col-12 col-md-6 col-lg-3 mb-1">
                              <p className={`${cascading.match_text_1} mb-1`}>Match first 3</p>
                              <p className={`${cascading.match_text_2} mb-1`}>{(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[3]) /
                                          10000
                                        ).toFixed(2)}{" "} {LotterytokenSymbol}</p>
                              {/* <p className={`${cascading.match_text_3}`}>~$4,004</p> */}

                              </div>

                              <div className="col-12 col-md-6 col-lg-3 mb-1">
                              <p className={`${cascading.match_text_1} mb-1`}>Match first 4</p>
                              <p className={`${cascading.match_text_2} mb-1`}>{(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[2]) /
                                          10000
                                        ).toFixed(2)}{" "} {LotterytokenSymbol}</p>
                              {/* <p className={`${cascading.match_text_3}`}>~$5,004</p> */}

                              </div>

                              <div className="col-12 col-md-6 col-lg-3 mb-1">
                              <p className={`${cascading.match_text_1} mb-1`}>Match first 5</p>
                              <p className={`${cascading.match_text_2} mb-1`}>{(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[1]) /
                                          10000
                                        ).toFixed(2)}{" "} {LotterytokenSymbol}</p>
                              {/* <p className={`${cascading.match_text_3}`}>~$6,004</p> */}

                              </div>

                              <div className="col-12 col-md-6 col-lg-3 mb-1">
                              <p className={`${cascading.match_text_1} mb-1`}>Match all 6</p>
                              <p className={`${cascading.match_text_2} mb-1`}>{(
                                          (lotteryPrizeAmount *
                                            rewardsBreakdown[0]) /
                                          10000
                                        ).toFixed(2)}{" "} {LotterytokenSymbol}</p>
                              {/* <p className={`${cascading.match_text_3}`}>~$8,004</p> */}

                              </div>

                              {/* <div className="col-12 col-md-6 col-lg-3 mb-1">
                              <p className={`${cascading.match_text_1_pink} mb-1`}>Burn</p>
                              <p className={`${cascading.match_text_2} mb-1`}>2,325 {LotterytokenSymbol}</p>
                              <p className={`${cascading.match_text_3}`}>~$10,004</p>

                              </div> */}
                            </div>
                        </div>
                      )}
                      <button
                        className={`${cascading.detailsButton} mx-auto`}
                        onClick={() => setShowDetails(!showDetails)}
                      >
                        {showDetails ? (
                          <div className={`d-flex align-items-center`}>
                            <p className={`mb-0`}>Hide</p>
                            <FaChevronUp
                              fontSize={11}
                              className="ms-2 mb-1"
                            />{" "}
                          </div>
                        ) : (
                          <div className={`d-flex align-items-center`}>
                            <p className={`mb-0`}>Details</p>
                            <FaChevronDown
                              fontSize={11}
                              className="ms-2 mb-1"
                            />{" "}
                          </div>
                        )}
                      </button>
                    </div>
                  </div>

      </Tab>
      <Tab eventKey="yourhistory" title="Your History">
        {iswalletConnect?
        <div className={`${cascading.ticketCard} `}>
        <div
          className={`${cascading.ticketCardTop} d-flex align-items-center justify-content-between`}
        >
          <div>
          <div className="d-flex align-items-center">
          <p className={`${cascading.cardTitle} mb-0`}>Round
          <div className={`${cascading.badge_blue} mb-0 badge ms-2`}>{currentLottery}</div>
          </p>
          </div>
          <p className={`${cascading.match_text_lottery} mt-3 mb-1`}>Drawn2 {moment(Endlotterytime).format('LLLL')}</p>
          </div>
          <p className={` ${cascading.cardSubTitle} mb-0 d-flex align-items-center`}>
          <div className={`${cascading.arrowpagination} ${cascading.disabled}`}>
          <span className={`${cascading.bartextleft}`}></span>

              <FaArrowLeft />     
              </div>
              <FaArrowLeft  className={`${cascading.arrowpagination}`}/>    
              <FaArrowRight  className={`${cascading.arrowpagination}`}/>     
              <div className={`${cascading.arrowpagination} ${cascading.disabled}`}>
              <FaArrowRight />     
              <span className={`${cascading.bartext}`}></span>
              </div>
          </p>
        </div>
        <div className={`${cascading.content}`}>
<div className={`${cascading.ribbondiv}`}>
<p className="mb-0">Latest</p>
</div>
          <div className={`row mx-auto align-items-center`}>
            <div
              className={`col-5 col-md-4 col-lg-3 col-xl-3 col-xxl-3 ps-0`}
            >
              <p className={`${cascading.cardTitle} mb-0`}>
               Winning Number
              </p>
            </div>{" "}
            <div
              className={`col-7 col-md-8 col-lg-9 col-xl-9 col-xxl-9`}
            >
             <div className="d-flex align-items-center justify-content-end">
              <div className={`${cascading.lotterydiv} ${cascading.lotteryfirst}`}>
             <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
             <p className={`${cascading.lotterynumber}`}>5</p>
             </div>

             <div className={`${cascading.lotterydiv} ${cascading.lotterysecond}`}>
             <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
             <p className={`${cascading.lotterynumber}`}>3</p>
             </div>

             <div className={`${cascading.lotterydiv} ${cascading.lotterythird}`}>
             <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
             <p className={`${cascading.lotterynumber}`}>0</p>
             </div>

             <div className={`${cascading.lotterydiv} ${cascading.lotteryfourth}`}>
             <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
             <p className={`${cascading.lotterynumber}`}>1</p>
             </div>

             <div className={`${cascading.lotterydiv} ${cascading.lotteryfifth}`}>
             <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
             <p className={`${cascading.lotterynumber}`}>6</p>
             </div>

             <div className={`${cascading.lotterydiv} ${cascading.lotterysixth}`}>
             <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
             <p className={`${cascading.lotterynumber}`}>7</p>
             </div>
             </div>
            </div>
          </div>{" "}
         
        </div>
        <div className={`${cascading.bottom} d-flex flex-column`}>
          {showDetails && (
            <div className={`${cascading.showDetails}`}>
              <p>
                Match the winning number in the same order to share
                prizes. Current prizes up for grabs:
              </p>                 
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6 mb-1">
                  <p className={`${cascading.match_text_2} mb-1`}>Prize Pot</p>
                  <p className={`${cascading.match_text_big} mb-1`}>{lotteryPrizeAmount.toFixed(4)} {LotterytokenSymbol}</p>
                  
                  {/* <p className={`${cascading.match_text_3}`}>21,125 {LotterytokenSymbol}</p> */}

                  </div>
                  {/* <div className="col-12 col-md-6 col-lg-6 mb-1">
                  <p className={`${cascading.match_text_2} mb-1`}>Total players this round</p>
                  
                  <p className={`${cascading.match_text_3}`}>300</p>

                  </div> */}
                </div>
                <hr className={`${cascading.hrlottery} ${cascading.hrlotterysm} d-none d-sm-block`} />
              <div className="row">
                <div className="col-12 col-md-6 col-lg-3 mb-1">
                  <p className={`${cascading.match_text_1} mb-1`}>Match first 1</p>
                  <p className={`${cascading.match_text_2} mb-1`}>232 {LotterytokenSymbol}</p>
                  <p className={`${cascading.match_text_3}`}>~$1,004</p>

                  </div>

                   <div className="col-12 col-md-6 col-lg-3 mb-1">
                  <p className={`${cascading.match_text_1} mb-1`}>Match first 2</p>
                  <p className={`${cascading.match_text_2} mb-1`}>232 {LotterytokenSymbol}</p>
                  <p className={`${cascading.match_text_3}`}>~$3,004</p>

                  </div>

                  <div className="col-12 col-md-6 col-lg-3 mb-1">
                  <p className={`${cascading.match_text_1} mb-1`}>Match first 3</p>
                  <p className={`${cascading.match_text_2} mb-1`}>232 {LotterytokenSymbol}</p>
                  <p className={`${cascading.match_text_3}`}>~$4,004</p>

                  </div>

                  <div className="col-12 col-md-6 col-lg-3 mb-1">
                  <p className={`${cascading.match_text_1} mb-1`}>Match first 4</p>
                  <p className={`${cascading.match_text_2} mb-1`}>232 {LotterytokenSymbol}</p>
                  <p className={`${cascading.match_text_3}`}>~$5,004</p>

                  </div>

                  <div className="col-12 col-md-6 col-lg-3 mb-1">
                  <p className={`${cascading.match_text_1} mb-1`}>Match first 5</p>
                  <p className={`${cascading.match_text_2} mb-1`}>232 {LotterytokenSymbol}</p>
                  <p className={`${cascading.match_text_3}`}>~$6,004</p>

                  </div>

                  <div className="col-12 col-md-6 col-lg-3 mb-1">
                  <p className={`${cascading.match_text_1} mb-1`}>Match all 6</p>
                  <p className={`${cascading.match_text_2} mb-1`}>232 {LotterytokenSymbol}</p>
                  <p className={`${cascading.match_text_3}`}>~$8,004</p>

                  </div>

                  {/* <div className="col-12 col-md-6 col-lg-3 mb-1">
                  <p className={`${cascading.match_text_1_pink} mb-1`}>Burn</p>
                  <p className={`${cascading.match_text_2} mb-1`}>2,325 {LotterytokenSymbol}</p>
                  <p className={`${cascading.match_text_3}`}>~$10,004</p>

                  </div> */}
                </div>
            </div>
          )}
          <button
            className={`${cascading.detailsButton} mx-auto`}
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? (
              <div className={`d-flex align-items-center`}>
                <p className={`mb-0`}>Hide</p>
                <FaChevronUp
                  fontSize={11}
                  className="ms-2 mb-1"
                />{" "}
              </div>
            ) : (
              <div className={`d-flex align-items-center`}>
                <p className={`mb-0`}>Details</p>
                <FaChevronDown
                  fontSize={11}
                  className="ms-2 mb-1"
                />{" "}
              </div>
            )}
          </button>
        </div>
      </div>
        :
        
          true?
          <>
{!showCont && 
          <div className={`${cascading.ticketCard} `}>
          <div
            className={`${cascading.ticketCardTop} d-flex align-items-center justify-content-between`}
          >
            <div>
            <div className="d-flex align-items-center">
            <p className={`${cascading.cardTitle} mb-0`}>Rounds
            </p>
            </div>
            </div>
        
          </div>
          
          <div className={`${cascading.content} ${cascading.withbottomradiuscontent}`}>
            {console.log("userTicketCount",userTicketCount)}
  {parseInt(userTicketCount) <= 0 ? <>
    <p className={`${cascading.cardTitle} text-center`}>No lottery history found</p>
        <p className={`${cascading.cardSubTitle} text-center`}>Buy tickets for the next round!</p>
        <div className={`text-center`}>
        <button className={`${cascading.buyTickets}`}>
                  Buy Tickets
                </button>
                </div></> : <>
                <div className={cascading.lotter__tableHolder}>
                <table className="table table-responsive table-striped">
                  <thead className={cascading.lotteryTableHead}>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Your Tickets</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className={cascading.lotteryTableBody}> 
                  {console.log("allHist",allHist)}
                  {allHist.map((data, key) => {
                    return (
<>
                    <tr>
                      <td>{data.ticketid}</td>
                      <td>{ moment(data.enddate).format('LLLL')}</td>
                      <td>{data.count}</td>
                      <td>
                        <div className={`d-flex justify-content-start align-items-center ${cascading.table__trophyDotter}`}>
                      <i className={`fa-solid fa-trophy me-2 ${cascading.table__trophy}`}/>
                        <button className={cascading.table__rightArrow} onClick={() => {setShowCont(true);enableOut(data.ticketid)} }><i class="fa-solid fa-angle-right"/></button>
                        
                      </div>
                        </td>
                    </tr>
                    {/* <tr>
                      <td>1</td>
                      <td>Apr 16, 2024 5:30 PM</td>
                      <td>1</td>
                      <td>
                      <div className={`d-flex justify-content-start align-items-center ${cascading.table__trophyDotter}`}>
                      <i className={`fa-solid fa-circle me-2  ${cascading.table__circle}`} />
                        <button className={cascading.table__rightArrow} onClick={() => setShowCont(true)}><i class="fa-solid fa-angle-right"/></button>
                        
                      </div>
                        </td>
                    </tr> */}
                    </>
                      );
                    })}
                  </tbody>
                  
                </table>
                </div></> }
        

           
          </div>
         
        </div>
        }

{showCont && 
          <div className={`${cascading.ticketCard} `}>
          <div
            className={`${cascading.ticketCardTop} d-flex align-items-center justify-content-between`}
          >
            <div>
            <div className="d-flex align-items-center">
            <p className={`${cascading.cardTitle} ${cascading.pointer} mb-0`}><i className={`fa-solid fa-arrow-left me-3`} onClick={() => setShowCont(false)} /> Round
            <div className={`${cascading.badge_blue} mb-0 badge ms-2`}>{currentLottery}</div>
            </p>
            </div>
       

            <p className={`${cascading.match_text_lottery} mt-3 mb-1`}>Drawn 3 {moment(Endlotterytime).format('LLLL')}</p>
            </div>
            <p className={` ${cascading.cardSubTitle} mb-0 d-flex align-items-center`}>
            <div className={`${cascading.arrowpagination} ${cascading.disabled}`}>
  
  <span className={`${cascading.bartextleft}`}></span>
                {/* <FaArrowLeft />      */}
                </div>
                <FaArrowLeft  onClick={() => {historyChange("prev")}} className={`${cascading.arrowpagination}`}/>    
                <FaArrowRight onClick={() => {historyChange("next")}} className={`${cascading.arrowpagination}`}/>     
                <div className={`${cascading.arrowpagination} ${cascading.disabled}`}>
                {/* <FaArrowRight />      */}
                <span className={`${cascading.bartext}`}></span>
                </div>
            </p>
          </div>
          <div className={`${cascading.content}`}>
            {parseInt(Ticid) == parseInt(currentLottery) ? <div className={`${cascading.ribbondiv}`}>
  <p className="mb-0">Latest</p>
  </div> : <></>}
  
            <div className={`row mx-auto align-items-center`}>
              <div
                className={`col-5 col-md-4 col-lg-3 col-xl-3 col-xxl-3 ps-0`}
              >
                <p className={`${cascading.cardTitle} mb-0`}>
                 Winning Number
                </p>
              </div>{" "}
              <div
                className={`col-7 col-md-8 col-lg-9 col-xl-9 col-xxl-9`}
              >
               <div className="d-flex align-items-center justify-content-end">
                <div className={`${cascading.lotterydiv} ${cascading.lotteryfirst}`}>
               <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
               <p className={`${cascading.lotterynumber}`}>{TicfinalNumber[6] && TicfinalNumber[6] ? TicfinalNumber[6] : "0"}</p>
               </div>
  
               <div className={`${cascading.lotterydiv} ${cascading.lotterysecond}`}>
               <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
               <p className={`${cascading.lotterynumber}`}>{TicfinalNumber[5] && TicfinalNumber[5] ? TicfinalNumber[5] : "0"}</p>
               </div>
  
               <div className={`${cascading.lotterydiv} ${cascading.lotterythird}`}>
               <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
               <p className={`${cascading.lotterynumber}`}>{TicfinalNumber[4] && TicfinalNumber[4] ? TicfinalNumber[4] : "0"}</p>
               </div>
  
               <div className={`${cascading.lotterydiv} ${cascading.lotteryfourth}`}>
               <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
               <p className={`${cascading.lotterynumber}`}>{TicfinalNumber[3] && TicfinalNumber[3] ? TicfinalNumber[3] : "0"}</p>
               </div>
  
               <div className={`${cascading.lotterydiv} ${cascading.lotteryfifth}`}>
               <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
               <p className={`${cascading.lotterynumber}`}>{TicfinalNumber[2] && TicfinalNumber[2] ? TicfinalNumber[2] : "0"}</p>
               </div>
  
               <div className={`${cascading.lotterydiv} ${cascading.lotterysixth}`}>
               <svg viewBox="0 0 32 32" width="100%" height="100%" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-grXZZQ ktVOoD"><circle cx="16" cy="16" r="16" fill="#D750B2"></circle><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3428 3.13232C28.9191 8.87177 28.5505 17.2573 23.2373 22.5706C17.528 28.2799 8.27148 28.2799 2.56223 22.5706C2.2825 22.2909 2.01648 22.0026 1.76416 21.7067C4.02814 27.3486 9.54881 31.3326 16 31.3326C24.4683 31.3326 31.3332 24.4677 31.3332 15.9994C31.3332 10.6078 28.5504 5.8661 24.3428 3.13232Z" fill="black"></path></g><g opacity="0.1"  className={`${cascading.mixblend}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7713 4.18262C30.6308 10.2119 30.2607 19.061 24.6609 24.6608C19.0615 30.2602 10.2132 30.6307 4.18396 25.7722C6.99643 29.1689 11.2455 31.3329 16 31.3329C24.4683 31.3329 31.3332 24.468 31.3332 15.9997C31.3332 11.2446 29.1687 6.99508 25.7713 4.18262Z" fill="black"></path></g><g  className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M3.48969 24.8677C0.151051 18.7651 0.974979 11.0636 6.01931 6.01927C11.0639 0.974682 18.7659 0.15093 24.8687 3.49016C22.365 1.71201 19.3046 0.666603 16 0.666603C7.53165 0.666603 0.666733 7.53152 0.666733 15.9998C0.666733 19.3041 1.7119 22.3642 3.48969 24.8677Z" fill="white"></path></g><g className={`${cascading.mixblendsoft}`}><path fill-rule="evenodd" clip-rule="evenodd" d="M2.10075 9.5143C3.77271 5.93677 6.78528 3.11129 10.4921 1.68422C10.546 1.73235 10.5987 1.78219 10.6502 1.83374C12.4838 3.66728 10.9119 5.7442 8.66145 7.99465C6.411 10.2451 4.33417 11.8169 2.50064 9.98335C2.35338 9.83609 2.22013 9.6793 2.10075 9.5143Z" fill="white"></path></g></svg>
               <p className={`${cascading.lotterynumber}`}>{TicfinalNumber[1] && TicfinalNumber[1] ? TicfinalNumber[1] : "0"}</p>
               </div>
               </div>
              </div>
            </div>{" "}
           
          </div>
          <div className={`${cascading.bottom} d-flex flex-column`}>
            {showDetails && (
              <div className={`${cascading.showDetails}`}>
                <p>
                  Match the winning number in the same order to share
                  prizes. Current prizes up for grabs:
                </p>                 
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <p className={`${cascading.match_text_2} mb-1`}>Prize Pot</p>
                    <p className={`${cascading.match_text_big} mb-1`}> {lotteryPrizeAmount.toFixed(4)} {LotterytokenSymbol}</p>
                    
                    {/* <p className={`${cascading.match_text_3}`}>21,125 {LotterytokenSymbol}</p> */}
  
                    </div>
                    {/* <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <p className={`${cascading.match_text_2} mb-1`}>Total players this round</p>
                    
                    <p className={`${cascading.match_text_3}`}>300</p>
  
                    </div> */}
                  </div>
                  <hr className={`${cascading.hrlottery} ${cascading.hrlotterysm} d-none d-sm-block`} />
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-3 mb-1">
                    <p className={`${cascading.match_text_1} mb-1`}>Match first 1</p>
                    <p className={`${cascading.match_text_2} mb-1`}>{((TiclotteryPrizeAmount *
                                            TicrewardsBreakdown[5]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                        {LotterytokenSymbol} </p>
                    {/* <p className={`${cascading.match_text_3}`}>~$1,004</p> */}
  
                    </div>
  
                     <div className="col-12 col-md-6 col-lg-3 mb-1">
                    <p className={`${cascading.match_text_1} mb-1`}>Match first 2</p>
                    <p className={`${cascading.match_text_2} mb-1`}>{((TiclotteryPrizeAmount *
                                            TicrewardsBreakdown[4]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                        {LotterytokenSymbol} {LotterytokenSymbol}</p>
                    {/* <p className={`${cascading.match_text_3}`}>~$3,004</p> */}
  
                    </div>
  
                    <div className="col-12 col-md-6 col-lg-3 mb-1">
                    <p className={`${cascading.match_text_1} mb-1`}>Match first 3</p>
                    <p className={`${cascading.match_text_2} mb-1`}>{((TiclotteryPrizeAmount *
                                            TicrewardsBreakdown[3]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                        {LotterytokenSymbol} </p>
                    {/* <p className={`${cascading.match_text_3}`}>~$4,004</p> */}
  
                    </div>
  
                    <div className="col-12 col-md-6 col-lg-3 mb-1">
                    <p className={`${cascading.match_text_1} mb-1`}>Match first 4</p>
                    <p className={`${cascading.match_text_2} mb-1`}>{((TiclotteryPrizeAmount *
                                            TicrewardsBreakdown[2]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                        {LotterytokenSymbol}</p>
                    {/* <p className={`${cascading.match_text_3}`}>~$5,004</p> */}
  
                    </div>
  
                    <div className="col-12 col-md-6 col-lg-3 mb-1">
                    <p className={`${cascading.match_text_1} mb-1`}>Match first 5</p>
                    <p className={`${cascading.match_text_2} mb-1`}>{((TiclotteryPrizeAmount *
                                            TicrewardsBreakdown[1]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                         {LotterytokenSymbol}</p>
                    {/* <p className={`${cascading.match_text_3}`}>~$6,004</p> */}
  
                    </div>
  
                    <div className="col-12 col-md-6 col-lg-3 mb-1">
                    <p className={`${cascading.match_text_1} mb-1`}>Match all 6</p>
                    <p className={`${cascading.match_text_2} mb-1`}>{((TiclotteryPrizeAmount *
                                            TicrewardsBreakdown[0]) /
                                          10000
                                        ).toFixed(2)}{" "}
                                         {LotterytokenSymbol}</p>
                    {/* <p className={`${cascading.match_text_3}`}>~$8,004</p> */}
  
                    </div>
  
                    {/* <div className="col-12 col-md-6 col-lg-3 mb-1">
                    <p className={`${cascading.match_text_1_pink} mb-1`}>Burn</p>
                    <p className={`${cascading.match_text_2} mb-1`}>2,325 {LotterytokenSymbol}</p>
                    <p className={`${cascading.match_text_3}`}>~$10,004</p>
  
                    </div> */}
                  </div>
              </div>
            )}
            <button
              className={`${cascading.detailsButton} mx-auto`}
              onClick={() => setShowDetails(!showDetails)}
            >
              {showDetails ? (
                <div className={`d-flex align-items-center`}>
                  <p className={`mb-0`}>Hide</p>
                  <FaChevronUp
                    fontSize={11}
                    className="ms-2 mb-1"
                  />{" "}
                </div>
              ) : (
                <div className={`d-flex align-items-center`}>
                  <p className={`mb-0`}>Details</p>
                  <FaChevronDown
                    fontSize={11}
                    className="ms-2 mb-1"
                  />{" "}
                </div>
              )}
            </button>
          </div>
        </div>
        }
        </>
        :
        <div className={`${cascading.ticketCard} `}>
        <div
          className={`${cascading.ticketCardTop} d-flex align-items-center justify-content-between`}
        >
          <div>
          <div className="d-flex align-items-center">
          <p className={`${cascading.cardTitle} mb-0`}>Rounds
          </p>
          </div>
          </div>
      
        </div>
        
        <div className={`${cascading.content} ${cascading.withbottomradiuscontent}`}>

      <p className={`${cascading.cardTitle} text-center`}>No lottery history found</p>
      <p className={`${cascading.cardSubTitle} text-center`}>Buy tickets for the next round!</p>
      <div className={`text-center`}>
      <button className={`${cascading.buyTickets}`}>
                Buy Tickets
              </button>
              </div>
         
        </div>
       
      </div>

        }
     
     
      </Tab>
    
    </Tabs>

                   
                  </div>

                
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${cascading.playSec} pt-5 pb-2`}>
          <div className={`container`}>
          <h4 className={`${cascading.title} ${cascading.titlepurple} text-center`}>
          How to Play
                  </h4>
                  <div className="row">
                    <div className="col-12 col-md-11 col-lg-8 mx-auto mt-3">
                    <p className={`${cascading.subtitleblk} text-center`}>If the digits on your tickets match the winning numbers in the correct order, you win a portion of the prize pool.</p>

                    </div>
                  </div>

                  <div className="row mt-4 align-items-center justify-content-center">
                    <div className="col-12 col-md-6 col-lg-4 mb-3 mb-lg-0">
                      <div className={`${cascading.cardplay} h-100`}>
                        <p className={`${cascading.cardsteps}`}>Step 1</p>
                        <p className={`${cascading.cardtitlebuy}`}>Buy Tickets</p>
                        <p className={`${cascading.carddescbuy}`}>Prices are set when the round starts, equal
to 5 USD in {LotterytokenSymbol} per ticket.</p>

                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 mb-3 mb-lg-0 ">
                      <div className={`${cascading.cardplay} h-100`}>
                        <p className={`${cascading.cardsteps}`}>Step 2</p>
                        <p className={`${cascading.cardtitlebuy}`}>Wait for the Draw</p>
                        <p className={`${cascading.carddescbuy}`}>There is one draw every day alternating
between 0 AM UTC and 12 PM UTC.</p>

                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 mb-3 mb-lg-0">
                      <div className={`${cascading.cardplay} h-100`}>
                        <p className={`${cascading.cardsteps}`}>Step 3</p>
                        <p className={`${cascading.cardtitlebuy}`}>Check for Prizes</p>
                        <p className={`${cascading.carddescbuy}`}>Once the round’s over, come back to the
page and check to see if you’ve won!.</p>

                      </div>
                    </div>
                  </div>
                  <hr className={`${cascading.hrlottery}`}/>
            </div>
          </div>

          <div className={`${cascading.playSec} pb-2`}>
          <div className={`container`}>
          <h4 className={`${cascading.title} ${cascading.titlepurple} text-center`}>
          Winning Criteria
                  </h4>
                  <div className="row">
                    <div className="col-12 col-md-11 col-lg-8 mx-auto mt-3">
                    <p className={`${cascading.subtitleblk} text-center`}>The digits on your ticket must match in the correct order to win.</p>
                    <p className={`${cascading.subtitleblksm} text-center`}>Here’s an example lottery draw, with two tickets, A and B.</p>

                    </div>
                  </div>


<div className="row mt-4 align-items-center">
  <div className="col-12 col-md-4 text-center text-md-left">
  <img src={play1} className="img-fluid" />
  </div>
  <div className="col-12 col-md-8">
  <div className={`${cascading.cardplay} h-100  mt-3 mt-md-0`}>
                        <p className={`${cascading.carddescbuy}`}>Ticket A: The first 3 digits and the last 2 digits match, but the 4th digit is wrong, so this ticket only wins a “Match first 3” prize.</p>
                        <p className={`${cascading.carddescbuy} mb-0`}>Ticket B: Even though the last 5 digits match, the first digit is wrong, so this ticket doesn’t win a prize.</p>
                        <p className={`${cascading.carddescbuy}`}>Prize brackets don’t ‘stack’: if you match the first 3 digits in order, you’ll only win prizes from the ‘Match 3’
bracket, and not from ‘Match 1’ and ‘Match 2’.</p>
                      </div>
  </div>
</div>
                

                
                  <hr className={`${cascading.hrlottery}`}/>
            </div>
          </div>

          <div className={`${cascading.playSec} pb-5`}>
          <div className={`container`}>
          <h4 className={`${cascading.title} ${cascading.titlepurple} text-center`}>
          Prize Funds
                  </h4>
                  
                  <div className="row">
                    <div className="col-12 col-md-11 col-lg-8 mx-auto mt-3">
                    <p className={`${cascading.subtitleblk} text-center`}>The prizes for each lottery round come from three sources:</p>

                    </div>
                  </div>


                  <div className={`${cascading.flexrev} row mt-4 align-items-center`}>
                  <div className="col-12 col-md-8">
  <div className={`${cascading.cardplay} h-100  mt-3 mt-md-0`}>
  <p className={`${cascading.cardtitlebuy} ${cascading.cardtitlebuysm} mb-1`}>Ticket Purchases</p>
                        <p className={`${cascading.carddescbuy}`}>100% of the {LotterytokenSymbol} paid by people buying tickets that round goes back into the prize pools.</p>
                        <p className={`${cascading.cardtitlebuy} ${cascading.cardtitlebuysm} mb-1`}>Rollover Prizes</p>
                        <p className={`${cascading.carddescbuy}`}>After every round, if nobody wins in one of the prize brackets, the unclaimed {LotterytokenSymbol} for that bracket rolls over into the next round and are redistributed among the prize pools.</p>
                        <p className={`${cascading.cardtitlebuy} ${cascading.cardtitlebuysm} mb-1`}>{LotterytokenSymbol} Injections</p>
                        <p className={`${cascading.carddescbuy} mb-0`}>An average total of 35,000 {LotterytokenSymbol} from the treasury is added to lottery rounds over the course of a week.</p>
          <p className={`${cascading.carddescbuy}`}>This {LotterytokenSymbol} is of course also included in rollovers! Read more in our guide to {LotterytokenSymbol} Tokenomics</p>
                      
                      </div>
  </div>
  <div className="col-12 col-md-4 text-center text-md-left">
  <img src={play2} className="img-fluid" />
  </div>

</div>

                
            </div>
          </div>
      </div>
      <Footer />

      {buyticketmodal && (
        <Buyticketmodal
          show={buyticketmodal}
          onShowEdit={() => {setEditticketmodal(true);setBuyticketmodal(false);}}
          onHide={() => setBuyticketmodal(false)}
          TicketNumberData={(data) => { setticketNumber(data)}}
          LotteryAmount={(data) => { setLotteryAmount(data)}}
        />
      )}

{viewticketmodal && (
        <Viewticketmodal
          show={viewticketmodal}
          onShowBuy={() => {setBuyticketmodal(true);setViewticketmodal(false);}}
          ticketdetails = {currentLottery}
          
          onHide={() => setViewticketmodal(false)}
        />
      )}


{editticketmodal && (
        <Editticketmodal
          show={editticketmodal}
          onShowBuy={() => {setEditticketmodal(false);setBuyticketmodal(true);}}
          ticketNumber={ticketNumber}
          LotteryAmount={lotteryamount}
          onHide={() => setEditticketmodal(false)}
        />
      )}




    </div>
  );
};

export default Lottery;
