import React, { useState, useEffect } from "react";
import { IoMdSettings } from "react-icons/io";
import { MdHistory } from "react-icons/md";
import cascading from "../../assests/css/liquidity.module.css";
import Footer from "../../common/footer";
import { FaArrowDown } from "react-icons/fa";
import Header from "../../common/header";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import bnbimg from "../../assests/images/bnb.png";
import Walletmodal from "../../modals/walletmodal";
import { useSelector } from "react-redux";
import Recenthistory from "../../modals/recenthistory";
import Selecttoken from "../../modals/selecttoken";
import Settingsmodal from "../../modals/settingsmodal";
import BigNumber from "bignumber.js";
import infoicon from "../../assests/images/infoicon.png";
import { IsCurrency } from '../../hooks/useCommon';
import {roundToSignificant} from '../../hooks/useCommon'
//hooks
import { GetallPairsLength, GetLiquidityETH } from "../../hooks/useFactory";
import {
  getTokens,
  GetCurrencyBalance,
  multicall,
} from "../../hooks/useTokens";
import { ApproveToken, checkIsApproved, Balance } from "../../hooks/useErc20";
import {
  RemoveLiquidity,
  RemoveLiquidityETH,
  RemoveliquidityWithPermit,
} from "../../hooks/useRouter";

//abi
import pair_ABI from "../../config/abi/pairAbi.json";
import ERC20_ABI from "../../config/abi/ERC20.json";

//lib
import fileObjectUrl from "../../lib/img";
import { isEmpty } from "../../lib/isEmpty";
import { toFixedNumber } from "../../lib/FixedNumber";

import { Accordion } from "react-bootstrap";
import { getWalletAddress } from "../../lib/localStorage";
import HeaderTabs from "../../common/HeaderTabs";

const headerTabsNames = [
  {
    id: 1,
    name: "Swap",
    linkTo: "/swap",
  },
  {
    id: 2,
    name: "Liquidity",
    linkTo: "/liquidity",
  },
  // {
  //   id: 3,
  //   name: "Buy Crypto",
  //   linkTo: "/buycrypto",
  // }
];

export default function Liquidity() {
  const navigate = useNavigate();
  const [swapsec, setSwapsec] = useState(true);
  const [walletmodalshow, setWalletmodalshow] = useState(false);
  const [recentshow, setRecentshow] = useState(false);
  const [tokenshow, setTokenshow] = useState(false);
  const [settingshow, setSettingshow] = useState(false);
  const userdetail = useSelector((state) => state.admin);
  const [pairLength, setPairLength] = useState(0);
  const [token, setToken] = useState([]);
  const [poolShare, setPoolShare] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPair();
    // GetLiquidityETH()
  }, []);

  // useEffect(()=>{
  //     RemoveliquidityWithPermit()
  // })

  const getPair = async () => {
    console.log("data", data, Array(data));
    var data = await GetallPairsLength();
    console.log("data", data, Array(data));
    setPairLength(data);
    let result = [];
    if (data > 0) {
      setLoading(true);

    //   for (let i = 0; i < data; i++) {
    //     let address = await GetLiquidityETH(i);
    //     let {
    //       reserveA,
    //       reserveB,
    //       tokenA,
    //       tokenB,
    //       lpBalance,
    //       tokenAbal,
    //       tokenBbal,
    //       totalSupply,
    //       userlpBalance,
    //     } = await getcall(address);
    //     // console.log("tokenA", tokenA,lpBalance*100/totalSupply)
    //     let token1 = getTokens().find(
    //       (val) => val?.address?.toLowerCase() == tokenA?.toLowerCase()
    //     );
    //     console.log("token1", token1, totalSupply);
    //     let token2 = getTokens().find(
    //       (val) => val?.address?.toLowerCase() == tokenB?.toLowerCase()
    //     );
    //     let obj = {
    //       reserveA: reserveA,
    //       reserveB: reserveB,
    //       token1: { ...token1, balance: tokenAbal },
    //       token2: { ...token2, balance: tokenBbal },
    //       lpBalance: lpBalance,
    //       totalSupply: totalSupply,
    //       lpaddress: address,
    //       // userlpBalance:userlpBalance,
    //       poolShare: ((lpBalance * 100) / totalSupply).toFixed(2),
    //     };
    //     setPoolShare(((lpBalance * 100) / totalSupply).toFixed(2));
    //     result.push(obj);
    //     if (i == data - 1) {
    //       console.log();
    //       setToken(result);
    //       setLoading(false);
    //     }
    //     console.log("token2", token2);
    //   }
    // } else {
    //   setLoading(false);
    // }

    for (let i = 0; i < data; i++) {
      let address = await GetLiquidityETH(i)
      let { reserveA, reserveB, tokenA, tokenB, lpBalance, tokenAbal, tokenBbal, totalSupply, userlpBalance } = await getcall(address)
      console.log("tokenA", toFixedNumber(reserveA), toFixedNumber(reserveB))
      let token1 = getTokens().find((val) => (val?.address?.toLowerCase() == tokenA?.toLowerCase()));
      console.log("token1", token1, totalSupply)
      let token2 = getTokens().find((val) => (val?.address?.toLowerCase() == tokenB?.toLowerCase()));
      let currencyBal
      // console.log("currencyBal",token1?.symbol == 'BNB' || token2?.symbol == 'BNB')
      // if(token1?.symbol == 'BNB' || token2?.symbol == 'BNB' ){
      if(IsCurrency(token1) || IsCurrency(token2)){
          currencyBal =  await GetCurrencyBalance()
          // console.log("currencyBal",currencyBal,token1.symbol == 'BNB' ? { ...token1, 'balance': currencyBal }: { ...token1, 'balance': tokenAbal })
      }
      let obj = {
          reserveA: reserveA,
          reserveB: reserveB,
          token1: IsCurrency(token1) ? { ...token1, 'balance': currencyBal }: { ...token1, 'balance': tokenAbal },
          token2:IsCurrency(token2) ? { ...token2, 'balance': currencyBal }:{ ...token2, 'balance': tokenBbal },
          lpBalance: lpBalance,
          totalSupply: totalSupply,
          lpaddress: address,
          // userlpBalance:userlpBalance,
          poolShare: (lpBalance * 100 / totalSupply).toFixed(2)
      }
      setPoolShare((lpBalance * 100 / totalSupply).toFixed(2))
      if(obj.lpBalance > 0){
          result.push(obj)
      }
     
      if (i == data - 1) {
          console.log()
          setToken(result)
          setLoading(false)

      }
      console.log("token2", token2)
  }
} else {
  setLoading(false)
}
  };

  // const getcall = async (address) => {
  //   let calls = [
  //     {
  //       address: address,
  //       name: "getReserves",
  //     },
  //     {
  //       address: address,
  //       name: "token0",
  //     },
  //     {
  //       address: address,
  //       name: "token1",
  //     },
  //     {
  //       address: address,
  //       name: "totalSupply",
  //     },
  //   ];
  //   if (!isEmpty(getWalletAddress())) {
  //     calls.push({
  //       address: address,
  //       name: "balanceOf",
  //       params: [getWalletAddress()],
  //     });
  //   }
  //   console.log("calls_callscalls", calls);
  //   var pair = await multicall(pair_ABI, calls);
  //   console.log("pair", pair);
  //   let call = [
  //     {
  //       address: pair[1][0],
  //       name: "balanceOf",
  //       params: [getWalletAddress()],
  //     },
  //     {
  //       address: pair[2][0],
  //       name: "balanceOf",
  //       params: [getWalletAddress()],
  //     },
  //   ];
  //   var tokenbalance = await multicall(ERC20_ABI, call);
  //   console.log("tokenbalance", tokenbalance);
  //   // var bal = await Balance(address)
  //   // console.log("bal", bal)

  //   return {
  //     reserveA: new BigNumber(pair[0][0]._hex).toNumber() / 10 ** 18,
  //     reserveB: new BigNumber(pair[0][1]._hex).toNumber() / 10 ** 18,
  //     tokenA: pair[1][0],
  //     tokenB: pair[2][0],
  //     totalSupply: new BigNumber(pair[3][0]._hex).toNumber() / 10 ** 18,
  //     // userlpBalance: new BigNumber(pair[4]._hex).toNumber() / 10 ** 18,
  //     lpBalance: isEmpty(getWalletAddress())
  //       ? 0
  //       : new BigNumber(pair[4][0]._hex).toNumber() / 10 ** 18,
  //     tokenAbal: isEmpty(getWalletAddress())
  //       ? 0
  //       : new BigNumber(tokenbalance[0]).toNumber() / 10 ** 18,
  //     tokenBbal: isEmpty(getWalletAddress())
  //       ? 0
  //       : new BigNumber(tokenbalance[1]).toNumber() / 10 ** 18,
  //   };
  // };

  const getcall = async (address) => {
    let calls = [{
        address: address,
        name: 'getReserves'
    }, {
        address: address,
        name: 'token0'
    }, {
        address: address,
        name: 'token1'
    },
    {
        address: address,
        name: 'totalSupply'
    }
    ]
    if (!isEmpty(getWalletAddress())) {
        calls.push({
            address: address,
            name: 'balanceOf',
            params: [getWalletAddress()]
        })
    }
    console.log("calls_callscalls", calls)
    var pair = await multicall(pair_ABI, calls)
    console.log("pair", pair)
    let call = [{
        address: pair[1][0],
        name: 'balanceOf',
        params: [getWalletAddress()]
    }, {
        address: pair[2][0],
        name: 'balanceOf',
        params: [getWalletAddress()]
    }]
    var tokenbalance = await multicall(ERC20_ABI, call)
    console.log("tokenbalance", tokenbalance)
    // var bal = await Balance(address)
    // console.log("bal", bal)

    return {
        reserveA: new BigNumber(pair[0][0]._hex).toNumber() / 10 ** 18,
        reserveB: new BigNumber(pair[0][1]._hex).toNumber() / 10 ** 18,
        tokenA: pair[1][0],
        tokenB: pair[2][0],
        totalSupply: new BigNumber(pair[3][0]._hex).toNumber() / 10 ** 18,
        // userlpBalance: new BigNumber(pair[4]._hex).toNumber() / 10 ** 18,
        lpBalance: isEmpty(getWalletAddress()) ? 0 : new BigNumber(pair[4][0]._hex).toNumber() / 10 ** 18,
        tokenAbal: isEmpty(getWalletAddress()) ? 0 : new BigNumber(tokenbalance[0]).toNumber() / 10 ** 18,
        tokenBbal: isEmpty(getWalletAddress()) ? 0 : new BigNumber(tokenbalance[1]).toNumber() / 10 ** 18,

    }
}

  return (
    <>
      <Header />
      <HeaderTabs headerTabsNames={headerTabsNames} />
      <div className={`${cascading.innerpages} ${cascading.liquiditypage}`}>
        <div className={`container`}>
          <div
            className={`row justify-content-center ${cascading.swapcardrow}`}
          >
            <div className={`col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5`}>
              <div className={` ${cascading.cardstyle}`}>
                <div className={`${cascading.toprow}`}>
                  <div>
                    <p className={`${cascading.headings}`}>Your Liquidity</p>
                    <p className={`${cascading.desc}`}>
                      Remove liquidity to receive tokens back
                    </p>
                  </div>
                  <div className={`${cascading.iconsec}`}>
                    <IoMdSettings onClick={() => setSettingshow(true)} />
                    <MdHistory onClick={() => setRecentshow(true)} />
                  </div>
                </div>
                <div className={`${cascading.innercontentsec} mb-4`}>
                  {console.log(
                    userdetail?.walletStatus !== "connect" &&
                      !isEmpty(getWalletAddress()),
                    userdetail?.walletStatus,
                    userdetail?.walletStatus == "connect",
                    !isEmpty(getWalletAddress()),
                    getWalletAddress(),
                    "condition_cheeck"
                  )}
                  {userdetail?.walletStatus == "connect" &&
                  !isEmpty(getWalletAddress()) ? (
                    <>
                      {console.log("token_token", token)}

                      {/* { ?  :""} */}

                      {loading && loading? (
                        <p className={`${cascading.labelname} mb-4`}>
                          Loading...
                        </p>
                      ) : token && token?.length == 0 ? (
                        <p className={`${cascading.labelname} mb-4`}>
                          No liquidity found.
                        </p>
                      ) : (
                        token &&  token?.map((val, i) => {
                          return (
                            <div className={`${cascading.poolaccordion}`}>
                              {val?.lpBalance !== 0 ? (
                                <>
                                  <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey={i}>
                                      <Accordion.Header>
                                        <div>
                                          <p
                                            className={`${cascading.labelhead}`}
                                          >
                                            <img
                                              src={fileObjectUrl(
                                                val?.token1?.image
                                              )}
                                              alt="image"
                                              className={`img-fluid ${cascading.coinimg}`}
                                            />{" "}
                                            <img
                                              src={fileObjectUrl(
                                                val?.token2?.image
                                              )}
                                              alt="image"
                                              className={`img-fluid ${cascading.coinimg}`}
                                            />{" "}
                                            {`${val?.token1?.symbol}/${val?.token2?.symbol}`}
                                          </p>
                                          <p className={`${cascading.balance}`}>
                                            {" "}
                                            {/* {val?.lpBalance} */}
                                            {roundToSignificant(val?.lpBalance,6)}
                                          </p>
                                        </div>
                                      </Accordion.Header>

                                      <Accordion.Body>
                                        <div>
                                          <ul
                                            className={`${cascading.poolaccordion_ul}`}
                                          >
                                            <li>
                                              {console.log(
                                                "val?.token1?.image",
                                                val?.token1?.image
                                              )}
                                              <p
                                                className={`${cascading.labelname}`}
                                              >
                                                <img
                                                  src={fileObjectUrl(
                                                    val?.token1?.image
                                                  )}
                                                  alt="image"
                                                  className={`img-fluid ${cascading.coinimg} me-2`}
                                                />
                                                Pooled {val?.token1?.symbol}
                                              </p>
                                              <p
                                                className={`${cascading.labelname}`} >{roundToSignificant(val?.reserveA,6)}
                                              
                                                {" "}
                                                {toFixedNumber(val?.reserveA)}
                                              </p>
                                            </li>
                                            <li>
                                              <p
                                                className={`${cascading.labelname}`}
                                              >
                                                <img
                                                  src={fileObjectUrl(
                                                    val?.token2?.image
                                                  )}
                                                  alt="image"
                                                  className={`img-fluid ${cascading.coinimg} me-2`}
                                                />
                                                Pooled {val?.token2?.symbol}
                                              </p>
                                              <p
                                                className={`${cascading.labelname}`}
                                              >{roundToSignificant(val?.reserveA,6)}
                                                {toFixedNumber(val?.reserveB)}
                                              </p>
                                            </li>
                                            <li>
                                              <p
                                                className={`${cascading.labelname}`}
                                              >
                                                Share of pool
                                              </p>
                                              <p
                                                className={`${cascading.labelname}`}
                                              >{roundToSignificant(val?.reserveA,6)}
                                                {val?.poolShare}
                                              </p>
                                            </li>
                                          </ul>
                                          {/* {val?.token1?.name !== "BNB" || val?.token2?.name !== "BNB" ?
                                                                        <button className={`btn ${cascading.walletbtn}`} onClick={() => { RemoveLiquidity(val?.token1?.address, val?.token2?.address) }}>Remove</button> :
                                                                        <button className={`btn ${cascading.walletbtn}`} onClick={() => { RemoveLiquidityETH() }}>Remove</button>} */}
                                          <button
                                            className={`btn ${cascading.walletbtn}`}
                                            onClick={() => {
                                              navigate("/remove", {
                                                state: { token: val },
                                              });
                                            }}
                                          >
                                            Remove
                                          </button>
                                          <button
                                            className={`btn ${cascading.transbtn}`}
                                            onClick={() => {
                                              navigate("/add", {
                                                state: { token: val },
                                              });
                                            }}
                                          >
                                            {" "}
                                            + Add Liquidity instead
                                          </button>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                  <div style={{ color: "white" }}></div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        })
                      )}

                      <div className={`${cascading.findbtnsec} mb-4`}>
                        <p className={`${cascading.labelname}`}>
                          Don't see a pool you joined ?
                        </p>
                        {/* <button
                          className={`${cascading.findbtn}`}
                          onClick={() => {
                            navigate("/find", {
                              state: { poolShare: poolShare },
                            });
                          }}
                        >
                          Find other LP tokens
                        </button> */}
                         <button className={`${cascading.findbtn}`} onClick={() => { navigate("/find", { state: { key: 'find', poolShare: poolShare } }) }} >Find other LP tokens</button>
                      </div>
                    </>
                  ) : (
                    <p className={`${cascading.labelname}`}>
                      Connect to a wallet to view your liquidity.
                    </p>
                  )}
                </div>
                <div className={`${cascading.btnsec}`}>
                  <Link
                    to="/add"
                    className={`btn ${cascading.connectwalletbtn} d-flex align-items-center justify-content-center`}
                  >
                    + Add Liquidity
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      {walletmodalshow && (
        <Walletmodal
          show={walletmodalshow}
          onHide={() => setWalletmodalshow(false)}
        />
      )}
      {recentshow && (
        <Recenthistory show={recentshow} onHide={() => setRecentshow(false)} />
      )}
      {tokenshow && (
        <Selecttoken show={tokenshow} onHide={() => setTokenshow(false)} />
      )}
      {settingshow && (
        <Settingsmodal
          show={settingshow}
          onHide={() => setSettingshow(false)}
        />
      )}
    </>
  );
}
