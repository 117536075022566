import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import cascading from "../assests/css/networkstyle.module.css";
import metaicon from "../assests/images/metamask.png";
import walleticon from "../assests/images/walletconnect.png";

import { IoMdClose } from "react-icons/io";
export default function Networkmodal(props) {
  const [netdetail, setNetdetail] = useState([
    {
      name: "Ethereum",
      img: require("../assests/images/eth.png"),
    },
    {
      name: "BNB Chain",
      img: require("../assests/images/bsc.png"),
    },
    {
      name: "Avalanche",
      img: require("../assests/images/Avalanche.png"),
    },
    {
      name: "Arbitrum",
      img: require("../assests/images/arb.png"),
    },
    {
      name: "zkSync",
      img: require("../assests/images/zkSync.png"),
    },
  ]);

  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal
        centered
        show={props.show}
        onHide={props.onHide}
        className={`${cascading.modalstyle}`}
        backdrop="static"
      >
        <Modal.Header
          className={`${cascading.modalclr} ${cascading.modalheader}`}
        >
          <h4 className={`${cascading.modaltitle} mb-0`}>Network</h4>
          <IoMdClose
            className={`${cascading.closebtn}`}
            onClick={props.onHide}
          />
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <p className={`${cascading.innerhead}`}>SELECT A NETWORK</p>
          <ul>
            {netdetail.map((e) => (
              <li>
                <button className={`${cascading.netbtn}`}>
                  <img src={e.img} className={`${cascading.netimg}`} alt="" />{" "}
                  {e.name}
                </button>
              </li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  );
}
