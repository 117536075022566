

// import constant
import {
    NETWORK_SWITCH
} from '../constants';

const initialValue = {
    networkSwitch: ''
}

//  console.log("initialValue----->>>",initialValue);
const networkSwitching = (state = initialValue, action) => {

    switch (action.type) {
        case NETWORK_SWITCH:
            return {
                ...state,
                networkSwitch: action.payload
            };
   
        default:
            return state;
    }
}

export default networkSwitching;