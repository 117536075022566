import React, { useEffect } from "react";
import cascading from "../assests/css/HeaderTabStyle.module.css";
import { NavLink, useLocation } from "react-router-dom";

const HeaderTabs = (props) => {
  const location = useLocation();
  const currentLocation = location.pathname;
  // const otherLinks = props.otherLinks;

  // const nammee = props?.otherLinks?.includes(currentLocation) ? "active" : "";

  // console.log(
  //   "propss",
  //   props?.headerTabsNames?.otherLinks?.includes(currentLocation)
  // );
  // console.log(
  //   "propss",
  //   props?.headerTabsNames?.map((val) =>{} if (val.otherLinks.length > 0) {

  //   } else {

  //   })
  // );

  const handleLocation = () => {
    const propsName = props?.headerTabsNames?.map((val) => val);
    console.log("props", propsName);
  };
  useEffect(() => {
    handleLocation();
  },[]);

  return (
    <div className={`${cascading.headTabs}`}>
      <ul className={`${cascading.tabNames}`}>
        {props.headerTabsNames?.map((val) => (
          <NavLink to={val.linkTo} className={``}>
            <li className={`${cascading.tabValues}`}>{val.name}</li>
          </NavLink>
        ))}
      </ul>
    </div>
  );
};

export default HeaderTabs;
