

// import constant
import {
    FARMS_DATA
} from '../constants';

const initialValue = {
    FarmData: {}
}

//  console.log("initialValue----->>>",initialValue);
const farmReducer = (state = initialValue, action) => {

    switch (action.type) {
        case FARMS_DATA:
            return {
                ...state,
                FarmData: action.payload
            };
   
        default:
            return state;
    }
}

export default farmReducer;