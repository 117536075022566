import { useWeb3 } from "./useWeb3";
import BigNumber from 'bignumber.js'

//lib
import { consolelog } from "../lib/consolelog";
import { getTokens } from "./useTokens";
import { toFixedNumber } from "../lib/FixedNumber";


export const EstGas = async (params, abi, contractAddress, methodName,account) => {
    try {
        const web3 = await useWeb3();
        const gasPrice = await web3.eth.getGasPrice();

        const gasPrice2 = localStorage.getItem('gasPrice')
        console.log(gasPrice,gasPrice2,"EstGas")
        
        const Contract = new web3.eth.Contract(abi, contractAddress)
        const encoded = await Contract.methods[methodName].apply(null,params).encodeABI();
        let estimatedGasLimit = await web3.eth.estimateGas({
            from:web3.utils.toChecksumAddress(account),
            to: web3.utils.toChecksumAddress(contractAddress),
            data: encoded,
        });

        return{
            gasLimit:parseInt(estimatedGasLimit* 1.5,10),
            gasPrice:JSON.parse(gasPrice2)?.gasPrice
        }
    } catch (e) {
        consolelog('EstGas_err',e,true);
        return false
    }
}

export const IsCurrency = (token)=>{
    try{
        if(token.isCoin){
            if(token.symbol.includes('W')){
                return false
            }
            return true
        }
        return false
    }catch(err){
        console.log(err,"IsCurrency__err")
        return false
    }
}

export const IsWrappedCurrency = (token)=>{
    try{
        if(token.isCoin){
            if(token.symbol.includes('W')){
                return true
            }else{
                return false
            }
        }
        return false
    }catch(err){
        console.log(err,"IsCurrency__err")
        return false
    }
}


export const Significant = (value) =>{
    try{
       let data = new BigNumber(value)
              console.log("Significant_data",data)
       return data
    }catch(err){
        console.log("Significant_err",err)
    }
}

export const WETHSymbol = ()=>{
    try{
        let Token  =  getTokens()?.filter((val)=>{ return (val.isCoin == true)})
        let weth = Token.find((val)=>(val.symbol.includes('W')))
        return weth.symbol
     }catch(err){
         console.log("Significant_err",err)
     }   
}

export const roundToSignificant= (num, significantFigures) => {
    console.log("roundToSignificant",num,significantFigures)
    num = parseFloat(num)
    if (num === 0) return 0; // Handle special case when num is 0
    const multiplier = Math.pow(10, significantFigures - Math.floor(Math.log10(Math.abs(num))) - 1);
    console.log("multiplier", Math.abs(num), Math.log10(Math.abs(num)), Math.pow(10, significantFigures - Math.floor(Math.log10(Math.abs(num))) - 1))
    console.log("round", Math.round(num * multiplier) / multiplier)
    let retunval=Math.round(num * multiplier) / multiplier
    retunval = isNaN(retunval) ? 0:retunval
    return toFixedNumber(retunval)
}

export const NumberChange = (data) => {
    try{
      return (isNaN(Number(data)) ? 0 : Number(data))
    }
    catch(err){
      console.log('NumberChange_error',err)
      return 0;
    }
  }