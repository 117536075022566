export const ORACLE = {
    11155111 :  {
       contract: "0x27709262cf4c7282C81291AD680fF462527FF708",
       decimals: 18,
       symbol: "BTCUSD"
    },
    97 : {
        contract: "0x920667ED2615bc053A4b156aCD48919D83F997cF",
        decimals: 8,
        symbol: "MBOX / USD"

    },
    walletAddress : "0x69E6E39FdF5c55470E69377ad3c7ae995aEA1bfb",
}