import React, { useState, useRef, useEffect } from "react";
import cascading from "../assests/css/PredictionFlipCard.module.css";
import { FaArrowDown, FaArrowLeft, FaArrowUp } from "react-icons/fa";
import toast from "react-hot-toast";
import { IoPlayCircleOutline } from "react-icons/io5";

// import RangeSlider from "react-range-slider-input";

import bscicon from "../assests/images/bscicon.png";
import Walletmodal from "../modals/walletmodal";
import { useBetBull, useBetBear } from "../hooks/usePrediction";
import { GetCurrencyBalance } from "../hooks/useTokens";

import RangeSlider from "react-bootstrap-range-slider";
import "bootstrap/dist/css/bootstrap.css";
import "react-range-slider-input/dist/style.css";

const PredictionFlipCard = ({
  setFlipCard,
  isConnectWallet,
  setIsConnectWallet,
  setIsConfirm,
  isDownClick,
  setIsDownClick,
  setEnterDown,
  upDown,
  setUpDown,
  Id,
}) => {
  // console.log("updown", upDown, Id);
  // const [upDown, setUpDown] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  const [up, setUp] = useState({});
  const [down, setDown] = useState({});
  const [errors, setError] = useState();
  const [balance, setBalance] = useState();
  const [upValue, setupValue] = useState(0);
  const [downValue, setDownValue] = useState(0);

  const [percentage, setPercentage] = useState(60);

  useEffect(() => {
    getBalance();
  }, []);

  const getBalance = async () => {
    var bal = await GetCurrencyBalance();
    setBalance(bal);
  };

  const HandleConfirm = async () => {
    let FetchUpdata = await useBetBull(Id, up?.Upamount);

    if (FetchUpdata.status == true) {
      setError({});
      setUp({ Upamount: "" });
      setupValue(0)
    } else {
      toast.error('Error while betUp');
    }
  };

  const HandleDownConfirm = async () => {
    let FetchDowndata = await useBetBear(Id, down?.Downamount);

    if (FetchDowndata.status == true) {
      setError({});
      setDown({ Downamount: "" });
      setDownValue(0)
    } else {
      toast.error('Error while betDown');
    }

  };

  const Uphandlechange = (e) => {
    setError({});
    var { name, value } = e.target;
    setUp({ ...up, [name]: value });
  };

  const Downhandlechange = (e) => {
    setError({});
    var { name, value } = e.target;
    setDown({ ...down, [name]: value });
  };

  const UpBalance = (index) => {
    setupValue(index);
    console.log("indexxx", index);
    var SetBalanceData = ((balance * index) / 100).toFixed(4);
    var name = "Upamount";
    setUp({ ...up, [name]: SetBalanceData });
  };

  const DownBalance = (index) => {
    setDownValue(index);
    var SetBalanceData = ((balance * index) / 100).toFixed(4);
    var name = "Downamount";
    setDown({ ...down, [name]: SetBalanceData });
  };

  const [value1, setValue1] = useState(1);
  const handleStyle1 = {
    color: "#fff",
    fontSize: 12,
    width: 32,
    height: 22,
  };
  const labels1 = ["1x", "5x", "10x", "15x", "20x", "25x"]; // Example labels

  const styles = {
    down_progress_fill: {
      position: "absolute",
      height: "7px",
      background: "#ed4b9e",
      zIndex: "2",
      borderRadius: "10px",
      width: `${downValue}%`,
    },
    up_progress_fill: {
      position: "absolute",
      height: "7px",
      background: "#7645d9",
      zIndex: "2",
      borderRadius: "10px",
      width: `${upValue}%`,
    },
    up_range_value_text: {
      fontSize: "10px",
      position: "absolute",
      zIndex: "3",
      top: "10px",
      color: "#7645d9",
      left: `${upValue - 2.5}%`,
    },
    down_range_value_text: {
      fontSize: "10px",
      position: "absolute",
      zIndex: "3",
      top: "10px",
      color: "#ed4b9e",
      left: `${downValue - 2.5}%`,
    },
  };

  return (
    <>
    {console.log('upValueupValue',upValue)}
      {isDownClick ? (
        <div
          className={`${cascading.predictionFlipCardPage} ${cascading.enterDown} enterdown`}
        >
          <div
            className={`${cascading.predictSliderCard} ${cascading.predictSliderCardGray}`}
          >
            <div className={`${cascading.cardSecondaryBg} mx-auto`}>
              <div
                className={`${cascading.topSection} d-flex align-items-center justify-content-between`}
              >
                <div
                  className={`${cascading.backButton} d-flex align-items-center gap-3`}
                >
                  <FaArrowLeft
                    onClick={() => {
                      setDown({ Downamount: "" });
                      setUp({ Upamount: "" });
                      setFlipCard(false);
                      setDownValue(0)
                      setupValue(0)
               
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <h5 className={`mb-0`}>Set Position</h5>
                </div>
                {upDown ? (
                  <button
                    className={`${cascading.upButton} d-flex align-items-center gap-2`}
                    onClick={() => {
                      setIsDownClick(!isDownClick);
                      setUpDown(!upDown);
                      setupValue(0)
                      setDownValue(0)
                      setUp({Upamount:""})
                      setDown({ Downamount: "" });
                    }}
                  >
                    <FaArrowUp fontSize={13} />
                    <p className={`mb-0`}>UP</p>
                  </button>
                ) : (
                  <button
                    className={`${cascading.downButton} d-flex align-items-center gap-2`}
                    onClick={() => {
                      setIsDownClick(!isDownClick);
                      setUpDown(!upDown);
                      setDownValue(0)
                      setupValue(0)
                      setUp({Upamount:""})
                      setDown({ Downamount: "" });
                    }}
                  >
                    <FaArrowDown fontSize={13} />
                    <p className={`mb-0`}>Down</p>
                  </button>
                )}
              </div>
              <div className={`${cascading.inputSection} `}>
                <div
                  className={`${cascading.inputTop} d-flex align-items-center justify-content-between`}
                >
                  <p className={`mb-0`}>Commit:</p>
                  <div
                    className={`${cascading.inputRight} d-flex align-items-center gap-1 `}
                  >
                    <div
                      className={`${cascading.iconBg} d-flex align-items-center justify-content-center`}
                    >
                      <img src={bscicon} alt="icon" className={`img-fluid`} />
                    </div>
                    <p className={`mb-0`}>BSC</p>
                  </div>
                </div>

                <input
                  type="text"
                  className={cascading.inputField}
                  name="Downamount"
                  id="Downamount"
                  value={down?.Downamount}
                  onChange={(e) => Downhandlechange(e)}
                />
              </div>
              {/* upvalue */}
              <div className={`${cascading.bottomSection}`}>
                {/* <div className={`predict_range`}>
                  <RangeSlider
                    className={`${cascading.single_thumb} mt-3`}
                    defaultValue={[0, 50]}
                    thumbsDisabled={[true, false]}
                    rangeSlideDisabled={true}
                  />
                </div> */}
                <div className="down_custom_range_slider   position-relative  mt-3">
                  {/* <div
                    className="down_progress_fill "
                    style={{ width: `${downValue - 0.5}%` }}
                  ></div> */}
                  <div
                    // className="down_progress_fill"
                    style={styles.down_progress_fill}
                  ></div>
                  <p style={styles.down_range_value_text}>{downValue}%</p>
                  {console.log("downvaluee", downValue)}
                  <RangeSlider
                    value={downValue}
                    onChange={(changeEvent) =>
                      DownBalance(changeEvent.target.value)
                    }
                  />
                </div>
                <div
                  className={`${cascading.percentage} d-flex align-items-center justify-content-between`}
                >
                  <div
                    className={`${cascading.percentValue} ${
                      downValue === 10 ? cascading.activePercentValue : ""
                    }`}
                    onClick={() => DownBalance(10)}
                  >
                    10%
                  </div>
                  <div
                    className={`${cascading.percentValue} ${
                      downValue === 25 ? cascading.activePercentValue : ""
                    }`}
                    onClick={() => DownBalance(25)}
                  >
                    25%
                  </div>
                  <div
                    className={`${cascading.percentValue} ${
                      downValue === 50 ? cascading.activePercentValue : ""
                    }`}
                    onClick={() => DownBalance(50)}
                  >
                    50%
                  </div>
                  <div
                    className={`${cascading.percentValue} ${
                      downValue === 75 ? cascading.activePercentValue : ""
                    }`}
                    onClick={() => DownBalance(75)}
                  >
                    75%
                  </div>
                  <div
                    className={`${cascading.percentValue} ${
                      downValue === 100 ? cascading.activePercentValue : ""
                    }`}
                    onClick={() => DownBalance(100)}
                  >
                    Max
                  </div>
                </div>
                {isConnectWallet ? (
                  <button
                    className={`${cascading.connectWallet}`}
                    onClick={() => HandleDownConfirm()}
                  >
                    {" "}
                    Confirm
                  </button>
                ) : (
                  <button
                    className={`${cascading.connectWallet}`}
                    onClick={() => setShowmodal(true)}
                  >
                    Connect wallet
                  </button>
                )}

                <p className={`${cascading.desc}`}>
                  You won’t be able to remove or change your position once you
                  enter it.
                </p>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className={`${cascading.predictionFlipCardPage}`}>
          <div
            className={`${cascading.predictSliderCard} ${cascading.predictSliderCardGray}`}
          >
            <div className={`${cascading.cardSecondaryBg} mx-auto`}>
              <div
                className={`${cascading.topSection} d-flex align-items-center justify-content-between`}
              >
                <div
                  className={`${cascading.backButton} d-flex align-items-center gap-3`}
                >
                  <FaArrowLeft
                    onClick={() => {
                      setDown({ Downamount: "" });
                      setUp({ Upamount: "" });
                      setFlipCard(false);
                      setDownValue(0)
                      setupValue(0)
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <h5 className={`mb-0`}>Set Position</h5>
                </div>
                {upDown ? (
                  <button
                    className={`${cascading.upButton} d-flex align-items-center gap-2`}
                    onClick={() => {
                      setIsDownClick(!isDownClick);
                      setUpDown(!upDown);
                    }}
                  >
                    <FaArrowUp fontSize={13} />
                    <p className={`mb-0`}>UP</p>
                  </button>
                ) : (
                  <button
                    className={`${cascading.downButton} d-flex align-items-center gap-2`}
                    onClick={() => {
                      setIsDownClick(!isDownClick);
                      setUpDown(!upDown);
                    }}
                  >
                    <FaArrowDown fontSize={13} />
                    <p className={`mb-0`}>Down</p>
                  </button>
                )}
              </div>
              <div className={`${cascading.inputSection} `}>
                <div
                  className={`${cascading.inputTop} d-flex align-items-center justify-content-between`}
                >
                  <p className={`mb-0`}>Commit:</p>
                  <div
                    className={`${cascading.inputRight} d-flex align-items-center gap-1 `}
                  >
                    <div
                      className={`${cascading.iconBg} d-flex align-items-center justify-content-center`}
                    >
                      <img src={bscicon} alt="icon" className={`img-fluid`} />
                    </div>
                    <p className={`mb-0`}>BSC</p>
                  </div>
                </div>
                <input
                  type="text"
                  className={cascading.inputField}
                  name="Upamount"
                  id="Upamount"
                  value={up?.Upamount}
                  onChange={(e) => Uphandlechange(e)}
                />
              </div>
              <div className={`${cascading.bottomSection}`}>
                {/* <div className={`predict_range`}>
                  <RangeSlider
                    className={`${cascading.single_thumb} mt-3`}
                    defaultValue={[0,percentage]}
                    thumbsDisabled={[true, false]}
                    rangeSlideDisabled={true}
                  />
                </div> */}
                <div className="custom_range_slider position-relative mt-3">
                  {/* <div
                    className="up_progress_fill"
                    style={{ width: `${upValue - 0.5}%` }}
                  ></div> */}
                  <div style={styles.up_progress_fill}></div>
                  <p style={styles.up_range_value_text}>{upValue}%</p>
                  <RangeSlider
                    value={upValue}
                    onChange={(changeEvent) =>
                      UpBalance(changeEvent.target.value)
                    }
                  />
                </div>

                {/* <Slider
                  progress
                  defaultValue={50}
                  onChange={(value) => {
                    console.log(value);
                  }}
                /> */}
                <div
                  className={`${cascading.percentage} d-flex align-items-center justify-content-between`}
                >
                  <div
                    className={`${cascading.percentValue} ${
                      upValue === 10 ? cascading.activePercentValue : ""
                    }`}
                    onClick={() => UpBalance(10)}
                  >
                    10%
                  </div>

                  <divs
                    className={`${cascading.percentValue}  ${
                      upValue === 25 ? cascading.activePercentValue : ""
                    }`}
                    onClick={() => UpBalance(25)}
                  >
                    25%
                  </divs>
                  <div
                    className={`${cascading.percentValue} ${
                      upValue === 50 ? cascading.activePercentValue : ""
                    }`}
                    onClick={() => UpBalance(50)}
                  >
                    50%
                  </div>
                  <div
                    className={`${cascading.percentValue} ${
                      upValue === 75 ? cascading.activePercentValue : ""
                    }`}
                    onClick={() => UpBalance(75)}
                  >
                    75%
                  </div>
                  <div
                    className={`${cascading.percentValue} ${
                      upValue === 100 ? cascading.activePercentValue : ""
                    }`}
                    onClick={() => UpBalance(100)}
                  >
                    Max
                  </div>
                </div>
                {isConnectWallet ? (
                  <button
                    className={`${cascading.connectWallet}`}
                    onClick={() => HandleConfirm()}
                  >
                    {" "}
                    Confirm
                  </button>
                ) : (
                  <button
                    className={`${cascading.connectWallet}`}
                    onClick={() => setShowmodal(true)}
                  >
                    Connect wallet
                  </button>
                )}

                <p className={`${cascading.desc}`}>
                  You won’t be able to remove or change your position once you
                  enter it.
                </p>
              </div>{" "}
            </div>
          </div>
        </div>
      )}
      {showmodal && (
        <Walletmodal show={showmodal} onHide={() => setShowmodal(false)} />
      )}{" "}
    </>
  );
};

export default PredictionFlipCard;
