import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import store from "./store";
import { Provider } from "react-redux";
import HelperRoute from "./helperRoutes";
// import { Redirect } from 'react-router'
import "bootstrap/dist/css/bootstrap.css";
import Landingpage from "./pages/Landingpage";
import Swap from "./pages/Trade/swap";
import Buycrypto from "./pages/Buycrypto/buycrypto";
import Liquidity from "./pages/Trade/liquidity";
import AddLiquidity from "./pages/Trade/addliquidity";
import FindLiquidity from "./pages/Trade/findliquidity";
import Farms from "./pages/Earn/Farms";
import Pools from "./pages/Earn/Pools";
import RemoveLiquidity from "./pages/Trade/removeliquidity";
import { MoonPayProvider } from '@moonpay/moonpay-react';

import Prediction from "./pages/Prediction/Prediction";
import Lottery from "./pages/Lottery";
import Lotterylist from "./pages/Lottery/lotterylist";
import Lotterysettings from "./pages/Lottery/lotterysettings";
import Addlottery from "./pages/Lottery/addlottery";
import { moonpayAPIkey } from "./config/env";

function App() {
  return (
    <MoonPayProvider 
    apiKey= {moonpayAPIkey}
  >
    <Provider store={store}>
      <BrowserRouter>
        <div>
          <Toaster />
        </div>

        <HelperRoute />

        <Routes>
     
          <Route exact path="/" element={<Landingpage />} />
          <Route exact path="/swap" element={<Swap />} />
          {/* <Route exact path="/buycrypto" element={<Buycrypto />} /> */}
          
          <Route exact path="/liquidity" element={<Liquidity />} />
          <Route exact path="/add" element={<AddLiquidity />} />
          <Route exact path="/find" element={<FindLiquidity />} />
          <Route exact path="/remove" element={<RemoveLiquidity />} />
          <Route exact path="/farms" element={<Farms />} />
          <Route exact path="/pools" element={<Pools />} />


          <Route exact path="/prediction" element={<Prediction />} />
          <Route exact path="/lottery" element={<Lottery />} />
          <Route exact path="/lotterylist" element={<Lotterylist />} />
          <Route exact path="/lotterysettings" element={<Lotterysettings />} />
          <Route exact path="/addlottery" element={<Addlottery />} />

         
        </Routes>
      </BrowserRouter>
    </Provider>
    </MoonPayProvider>
  );
}

export default App;
