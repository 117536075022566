import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import cascading from "../assests/css/buyticketstyle.module.css";
import { FaRegQuestionCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { IoMdClose } from "react-icons/io";
import {
  Claimrewards,
  UseviewUserInfoForLotteryId,
  ViewCurrentLottery,
  ViewNumberandStatus,
  getLotteryInfo,
  useViewCurrentLottery,
} from "../hooks/useLottery";
import toast from "react-hot-toast";
import { iconTheme, position, style } from "../hooks/useToast";

export default function Viewticketmodal(props) {
  // state
  const [viewticketmodal, setViewticketmodal] = useState(true);
  const [currentWinningNumber, SetcurrentWinningNumber] = useState([]);
  const [currentWinningNumberId, SetcurrentWinningNumberId] = useState([]);
  const [accountInfo, setaccountInfo] = useState(
    sessionStorage.getItem("accountInfo")
  );
  const [finalNumber, SetfinalNumber] = useState([]);
  const [TicfinalNumber, SetTicfinalNumber] = useState([]);
  const [ticid, Setticid] = useState();
  const [Endlotterytime, SetEndlotteryTime] = useState("");

  useEffect(() => {
    viewticketdetails();
  });

  async function viewticketdetails() {
    let id = props?.ticketdetails;
    // getclaimstatus(id)
    var lotteryinfo = await UseviewUserInfoForLotteryId(accountInfo, id);

    SetcurrentWinningNumber(lotteryinfo[1]);

    SetcurrentWinningNumberId(lotteryinfo[0]);
    var viewLotteryId = await ViewCurrentLottery(id);
    var digits = viewLotteryId?.finalNumber.toString().split("");

    var realDigits = digits.map(Number);

    SetfinalNumber(realDigits);
    SetTicfinalNumber(realDigits);
    Setticid(id);
    var lotteryinfo = await getLotteryInfo(accountInfo);
    var enddate = new Date(lotteryinfo?.viewcurrentLottery.endTime * 1000);
    SetEndlotteryTime(Date.parse(new Date(enddate)));
  }

  const claimRewards = async () => {
    var bracArray = [];
    var bracArrayTic = [];
    for (var i = 0; i < currentWinningNumber.length; i++) {
      var data = currentWinningNumber[i];
      var digits = data.toString().split("");
      var realDigits = digits.map(Number);
      var match = 10;

      if (
        realDigits[6] == TicfinalNumber[6] &&
        realDigits[5] == TicfinalNumber[5] &&
        realDigits[4] == TicfinalNumber[4] &&
        realDigits[3] == TicfinalNumber[3] &&
        realDigits[2] == TicfinalNumber[2] &&
        realDigits[1] == TicfinalNumber[1]
      ) {
        match = 5;
      } else if (
        realDigits[6] == TicfinalNumber[6] &&
        realDigits[5] == TicfinalNumber[5] &&
        realDigits[4] == TicfinalNumber[4] &&
        realDigits[3] == TicfinalNumber[3] &&
        realDigits[2] == TicfinalNumber[2]
      ) {
        match = 4;
      } else if (
        realDigits[6] == TicfinalNumber[6] &&
        realDigits[5] == TicfinalNumber[5] &&
        realDigits[4] == TicfinalNumber[4] &&
        realDigits[3] == TicfinalNumber[3]
      ) {
        match = 3;
      } else if (
        realDigits[6] == TicfinalNumber[6] &&
        realDigits[5] == TicfinalNumber[5] &&
        realDigits[4] == TicfinalNumber[4]
      ) {
        match = 2;
      } else if (
        realDigits[6] == TicfinalNumber[6] &&
        realDigits[5] == TicfinalNumber[5]
      ) {
        match = 1;
      } else if (realDigits[6] == TicfinalNumber[6]) {
        match = 0;
      }
      console.log(match, "matchmatchmatchmatch");
      if (match != 10) {
        bracArrayTic.push(currentWinningNumberId[i]);
        bracArray.push(match);
      }
    }
    if (bracArrayTic.length > 0) {
      var lotDetailsnewWithdraw = await ViewNumberandStatus(bracArrayTic);
      if (!lotDetailsnewWithdraw[1][0]) {
        var claimreward = await Claimrewards(
          ticid,
          bracArrayTic,
          bracArray,
          accountInfo
        );
      } else {
        await toast.error("Rewards Already claimed...!!!", {
          position: position.position,
          style: style,
          iconTheme: iconTheme,
        });
      }
    } else {
      await toast.error("There is no winning ticket to claim...!!!", {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
      });
    }
  };

  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal
          centered
          show={viewticketmodal}
          onHide={props.onHide}
          className={`${cascading.modalstyle}`}
          backdrop="static"
        >
          <Modal.Header
            className={`${cascading.modalclr} ${cascading.modalheader}`}
          >
            <h4 className={`${cascading.modaltitle} mb-0`}>Round #{ticid}</h4>
            <IoMdClose
              className={`${cascading.closebtn}`}
              onClick={props.onHide}
            />
          </Modal.Header>
          <Modal.Body
            className={`${cascading.modalclr} ${cascading.modalbody}`}
          >
            <p className={`${cascading.innerhead}`}>
              Your Total Tickets: #{currentWinningNumber.length}
            </p>
            <>
              {/* <p>#{ticid}</p> */}
              {currentWinningNumber.map((data, index) => {
                return (
                  <>
                    {/* var digits = data.toString().split("");
      var realDigits = digits.map(Number); */}
                    <p>#{index + 1}</p>
                    <div className={`${cascading.inputsec} `}>
                      <div className={`${cascading.balancerow}`}>
                        <p
                          className={`${cascading.inputlabelticket} mb-0 mt-0`}
                        >
                          {TicfinalNumber[1] ==
                          data.toString().split("").map(Number)[1] ? (
                            <span>
                              {data.toString().split("").map(Number)[1]}
                            </span>
                          ) : (
                            <span>
                              {data.toString().split("").map(Number)[1]}
                            </span>
                          )}
                          {TicfinalNumber[1] ==
                            data.toString().split("").map(Number)[1] &&
                          TicfinalNumber[2] ==
                            data.toString().split("").map(Number)[2] ? (
                            <span>
                              {data.toString().split("").map(Number)[2]}
                            </span>
                          ) : (
                            <span>
                              {data.toString().split("").map(Number)[2]}
                            </span>
                          )}

                          {TicfinalNumber[1] ==
                            data.toString().split("").map(Number)[1] &&
                          TicfinalNumber[2] ==
                            data.toString().split("").map(Number)[2] &&
                          TicfinalNumber[3] ==
                            data.toString().split("").map(Number)[3] ? (
                            <span>
                              {data.toString().split("").map(Number)[3]}
                            </span>
                          ) : (
                            <span>
                              {data.toString().split("").map(Number)[3]}
                            </span>
                          )}

                          {TicfinalNumber[1] ==
                            data.toString().split("").map(Number)[1] &&
                          TicfinalNumber[2] ==
                            data.toString().split("").map(Number)[2] &&
                          TicfinalNumber[3] ==
                            data.toString().split("").map(Number)[3] &&
                          TicfinalNumber[4] ==
                            data.toString().split("").map(Number)[4] ? (
                            <span>
                              {data.toString().split("").map(Number)[4]}
                            </span>
                          ) : (
                            <span>
                              {data.toString().split("").map(Number)[4]}
                            </span>
                          )}
                          {TicfinalNumber[1] ==
                            data.toString().split("").map(Number)[1] &&
                          TicfinalNumber[2] ==
                            data.toString().split("").map(Number)[2] &&
                          TicfinalNumber[3] ==
                            data.toString().split("").map(Number)[3] &&
                          TicfinalNumber[4] ==
                            data.toString().split("").map(Number)[4] &&
                          TicfinalNumber[5] ==
                            data.toString().split("").map(Number)[5] ? (
                            <span>
                              {data.toString().split("").map(Number)[5]}
                            </span>
                          ) : (
                            <span>
                              {data.toString().split("").map(Number)[5]}
                            </span>
                          )}

                          {TicfinalNumber[1] ==
                            data.toString().split("").map(Number)[1] &&
                          TicfinalNumber[2] ==
                            data.toString().split("").map(Number)[2] &&
                          TicfinalNumber[3] ==
                            data.toString().split("").map(Number)[3] &&
                          TicfinalNumber[4] ==
                            data.toString().split("").map(Number)[4] &&
                          TicfinalNumber[5] ==
                            data.toString().split("").map(Number)[5] &&
                          TicfinalNumber[6] ==
                            data.toString().split("").map(Number)[6] ? (
                            <span>
                              {data.toString().split("").map(Number)[6]}
                            </span>
                          ) : (
                            <span>
                              {data.toString().split("").map(Number)[6]}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </>
                );
              })}
              <hr />
            </>

            <div className={`${cascading.btnflexgap}`}>
              <button
                className={`btn ${cascading.connectwalletbtn}`}
                type="button"
                onClick={props.onShowBuy}
              >
                Buy Tickets
              </button>
              {new Date(Endlotterytime) >= new Date() ? (
                <button
                  className={`btn ${cascading.connectwalletbtn}`}
                  type="button"
                  disabled="true"
                >
                  Claim
                </button>
              ) : (
                <>
                  <button
                    className={`btn ${cascading.connectwalletbtn}`}
                    type="button"
                    onClick={() => claimRewards()}
                  >
                    Claim
                  </button>
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
