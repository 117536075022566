import React, { useEffect, useState } from "react";
import cascading from "../assests/css/poolcardstyle.module.css";
import logo from "../assests/images/coinicon.png";
import Walletmodal from "../modals/walletmodal";
import { FaAngleDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import {
  FaAngleUp,
  FaExternalLinkAlt,
  FaRegQuestionCircle,
  FaClock,
} from "react-icons/fa";
import { FaQuestion } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { AiOutlineCalculator } from "react-icons/ai";
import { Link } from "react-router-dom";
import Calculatormodal from "../modals/calculatormodal";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { CHAINS, EndBlock } from "../config/env";

//lib
import { isEmpty } from "../lib/isEmpty";
import { getWalletAddress } from "../lib/localStorage";
import { toFixedNumber } from "../lib/FixedNumber";

//hooks
import { ApproveToken, checkIsApproved, ImportToken } from "../hooks/useErc20";
import DbRequiremodal from "../modals/dbRequiremodal";
import Stakepoolmodal from "../modals/stakepoolmodal";
import Unstakepoolmodal from "../modals/unStakepoolmodal";
import { MAX_UNIT256 } from "../config/env";
import {
  getPoolAddress,
  Deposit,
  UsePool,
  getPoolBlockInfo,
} from "../hooks/usePools";
import { getTokens } from "../hooks/useTokens";
import { GetChainIndex } from "../hooks/useWeb3";
import {roundToSignificant } from '../hooks/useCommon';


export default function Poolscard(props) {
  const [showmodal, setShowmodal] = useState(false);
  const [dbrequire, setDbrequire] = useState(false);
  const [stakepoolmodal, setStakepoolmodal] = useState(false);
  const [unstakepoolmodal, setUnStakepoolmodal] = useState(false);
  const [value, setValue] =useState(115792089237316195423570985008687907853269984665640564039457584007913129639935);
  // const [stakemodal, setStakemodal] = useState(false);

  const [isenable, setIsenable] = useState(true);
  const [detailshow, setDetailshow] = useState(false);
  const [calshow, setcalshow] = useState(false);
  const [approve, setApprove] = useState(false);
  const [image, setImage] = useState("");
  const [shouldShowBlockCountdown, setShouldShowBlockCountdown] = useState();
  const [blocksUntilStart, setBlocksUntilStart] = useState();
  const [blocksRemaining, setBlocksRemaining] = useState();
  const [hasPoolStarted, setHasPoolStarted] = useState();
  const [blocksToDisplay, setBlocksToDisplay] = useState();
  const userdetail = useSelector((state) => state.admin);
  const { userData, datas } = props;

  useEffect(() => {
    console.log("props_pools", props, props?.status);
    tokens();
  },[]);

  // const Approve = async (address, value) => {

  //     var result = await checkIsApproved(address, value)
  //     setApprove(result)
  //     console.log("setApprove", result)
  // }

  // useEffect(() => {

  //     Approve(datas?.stakingToken?.address, value)

  // }, [datas?.stakingToken?.address, value])

  useEffect(() => {
    console.log(
      userData?.allowance,
      MAX_UNIT256,
      "userData?.allowance",
      toFixedNumber(userData?.allowance * 10 ** 18),
      userData?.stakedBalance
    );
    if (toFixedNumber(userData?.allowance * 10 ** 18) >= MAX_UNIT256) {
      setApprove(true);
    }
  }, [userData]);

  const tokens = async () => {
    let token = getTokens().find(
      (val) =>
        val?.address?.toLowerCase() ==
        datas?.stakingToken?.address?.toLowerCase()
    );
    console.log("token", token);
    setImage(token?.image);
  };

  const Harvesting = async () => {
    var res = await Deposit(0, datas?.contractAddress);
    console.log("res", res);
    if (res == true) {
      toast.success(
        `Your ${datas?.stakingToken?.symbol} earnings have also been harvested to your wallet!`
      );
      //   props.onHide()
    } else {
      toast.error("Try Again!");
    }
  };

  const endBlockInterval = async () => {
    const {
      shouldShowBlockCountdown,
      blocksUntilStart,
      blocksRemaining,
      hasPoolStarted,
      blocksToDisplay,
    } = await getPoolBlockInfo(
      datas?.startBlock,
      datas?.endBlock,
      datas?.isFinished
    );
    console.log(
      shouldShowBlockCountdown,
      blocksUntilStart,
      blocksRemaining,
      hasPoolStarted,
      blocksToDisplay,
      "setShouldShowBlockCountdown"
    );
    setShouldShowBlockCountdown(shouldShowBlockCountdown);
    setBlocksRemaining(blocksRemaining);
    setBlocksToDisplay(blocksToDisplay);
    setBlocksUntilStart(blocksUntilStart);
    setHasPoolStarted(hasPoolStarted);
  };

  useEffect(() => {
    setInterval(() => {
      endBlockInterval();
    }, 1000);
  }, [datas]);
  

  return (
    <>
      <div className={`${cascading.poolcard}`}>
        <div className={`${cascading.toprow}`}>
          {props?.status == "inactive" && (
            <>
              <div className={`${cascading.ribbon} ${cascading.ribbonright}`}>
                <span>Finished</span>
              </div>
            </>
          )}

          <div>
            <p className={`${cascading.mainlabel}`}>
              Earn {datas?.earningToken?.symbol}
            </p>
            <p className={`${cascading.sublabel}`}>
              Stake {datas?.stakingToken?.symbol}
            </p>
          </div>
          <div>
            <img
              src={image}
              className={`img-fluid ${cascading.logoimg}`}
              alt="img"
            />
          </div>
        </div>
        <div className={`${cascading.botrow}`}>
          <div className={`${cascading.botomHead}`}>
            <div className={`${cascading.aprrow} mb-3`}>
              <p className={`${cascading.label}`}>APR:</p>
              <p className={`${cascading.value}`}>
                {" "}
                {datas?.apr !== Infinity
                  ? datas?.apr.toLocaleString("en-Us")
                  : 0}{" "}
                %
              </p>
            </div>
            {userdetail?.walletStatus == "connect" &&
            !isEmpty(getWalletAddress()) ? (
              <div className={`${cascading.harvestrow} mb-3`}>
                <div>
                  <p className={`${cascading.label}`}>
                    {datas?.earningToken?.symbol} EARNED
                  </p>
                  {/* <p className={`${cascading.value}`}>
                    {toFixedNumber(
                      parseFloat(userData?.pendingReward / 10 ** 18).toFixed(10)
                    )}
                  </p> */}

<p className={`${cascading.value}`}>{roundToSignificant(userData?.pendingReward / 10 ** 18,6)}</p>
                  {/* <p className={`${cascading.balance}`}>{props.datas.balance} USD</p> */}
                </div>
                <div>
                  <button
                    className={`btn ${cascading.harvestbtn}`}
                    disabled={userData?.pendingReward == 0}
                    onClick={() => {
                      Harvesting();
                    }}
                  >
                    Harvest
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
            {console.log(
              "approve_approve",
              approve,
              parseFloat(userData?.stakedBalance) > 0
            )}

            <div className={`${cascading.btnsec}`}>
              {userdetail?.walletStatus == "connect" &&
              !isEmpty(getWalletAddress()) ? (
                <>
                  {approve ? (
                    !(parseFloat(userData?.stakedBalance) > 0) ? (
                      <>
                        {/* <p className={`${cascading.label}`}>STAKE</p> */}
                        <button
                          className={`${cascading.walletbtn} mt-3`}
                          onClick={() =>
                            userData.stakingTokenBalance < 0
                              ? setDbrequire(true)
                              : setStakepoolmodal(true)
                          }
                        >
                          STAKE
                        </button>
                      </>
                    ) : (
                      <>
                        <div className={`${cascading.harvestsec} mt-4`}>
                          <p className={`${cascading.label} mb-1`}>
                            {datas?.stakingToken?.symbol} STAKED
                          </p>
                          <div className={`${cascading.harvestrow}`}>
                            <div>
                              {/* <p className={`${cascading.label} mb-1`}>
                                {toFixedNumber(
                                  userData?.stakedBalance / 10 ** 18
                                )}
                              </p> */}
                                                    <p className={`${cascading.label} mb-1`}>{roundToSignificant(userData?.stakedBalance / 10 ** 18,6)}</p>

                            </div>
                            <div>
                              <div className="d-flex align-items-center justify-content-between">
                                <button
                                  className={`btn me-3 ${cascading.btnicon}`}
                                  onClick={() => {
                                    setUnStakepoolmodal(true);
                                  }}
                                  disabled={props?.status == "inactive"}
                                >
                                  <FaMinus />
                                </button>
                                <button
                                  className={`btn ${cascading.btnicon}`}
                                  onClick={() => {
                                    setStakepoolmodal(true);
                                  }}
                                  disabled={props?.status == "inactive"}
                                >
                                  <FaPlus />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  ) : (
                    <>
                      <p className={`${cascading.label}`}>
                        STAKE {datas?.stakingToken?.symbol}
                      </p>
                      <button
                        className={`${cascading.walletbtn} btn mt-3`}
                        onClick={async () => {
                          let enable = await ApproveToken(
                            datas?.stakingToken?.address,
                            getPoolAddress(datas?.contractAddress)
                          );
                          setApprove(enable?.status);
                        }}
                        disabled={approve || datas?.isFinished}
                      >
                        Enable
                      </button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {/* <p className={`${cascading.label}`}>STARTED EARNING</p> */}
                  <button
                    className={`${cascading.walletbtn}`}
                    onClick={() => setShowmodal(true)}
                  >
                    Connect Wallet
                  </button>
                </>
              )}
            </div>
          </div>
          <div className={`${cascading.bottomFooter}`}>
            <div className={`${cascading.detailsec}`}>
              <div className={`${cascading.mansec}`}>
                <p
                  className={`${cascading.corelabel} ${cascading.manuallabel}`}
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="14px"
                    fill="#fff"
                    class="sc-bdnxRM emNzIQ tagmanual"
                    color="secondary"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M17.65 6.35C16.02 4.72 13.71 3.78 11.17 4.04C7.50002 4.41 4.48002 7.39 4.07002 11.06C3.52002 15.91 7.27002 20 12 20C15.19 20 17.93 18.13 19.21 15.44C19.53 14.77 19.05 14 18.31 14C17.94 14 17.59 14.2 17.43 14.53C16.3 16.96 13.59 18.5 10.63 17.84C8.41002 17.35 6.62002 15.54 6.15002 13.32C5.31002 9.44 8.26002 6 12 6C13.66 6 15.14 6.69 16.22 7.78L14.71 9.29C14.08 9.92 14.52 11 15.41 11H19C19.55 11 20 10.55 20 10V6.41C20 5.52 18.92 5.07 18.29 5.7L17.65 6.35Z"></path>
                  </svg>{" "}
                  Manual
                </p>
                <OverlayTrigger
                  className={`${cascading.tooltip_style}`}
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      You must harvest and compound your earnings from this pool
                      manually.
                    </Tooltip>
                  }
                >
                  <Button className={`${cascading.tooltip_button}`}>
                    {/* <FaRegQuestionCircle /> */}
                    <FaQuestion />
                  </Button>
                </OverlayTrigger>
              </div>
              <div>
                {!detailshow ? (
                  <p
                    className={`${cascading.label}`}
                    onClick={() => setDetailshow(!detailshow)}
                  >
                    Details <FaAngleDown />
                  </p>
                ) : (
                  <p
                    className={`${cascading.label}`}
                    onClick={() => setDetailshow(!detailshow)}
                  >
                    Hide <FaAngleUp />
                  </p>
                )}
              </div>
            </div>
            {detailshow && (
              <div className={`${cascading.detailrow}`}>
                <div className={`${cascading.listrow}`}>
                  <p className={`${cascading.label}`}>Total Staked:</p>
                  <p className={`${cascading.value}`}>
                  {roundToSignificant(datas?.totalStaked / 10 ** 18,6)}{" "}
                    {datas?.stakingToken?.symbol}
                    <OverlayTrigger
                      className={`${cascading.tooltip_style}`}
                      key="right"
                      placement="left"
                      overlay={
                        <Tooltip id={`tooltip-left`}>
                          Total amount of {datas?.stakingToken?.symbol} staked
                          in this pool
                        </Tooltip>
                      }
                    >
                      <Button className={`${cascading.tooltip_button}`}>
                        {/* <FaRegQuestionCircle /> */}
                        <FaQuestion />
                      </Button>
                    </OverlayTrigger>
                  </p>
                </div>
                <div className={`${cascading.listrow}`}>
                  <p className={`${cascading.label}`}>Max.stake per user:</p>
                  <p className={`${cascading.value}`}>
                  {roundToSignificant(datas?.stakingLimit / 10 ** 18,6)}{" "}
                    {datas?.stakingToken?.symbol}{" "}
                    <OverlayTrigger
                      className={`${cascading.tooltip_style}`}
                      key="right"
                      placement="left"
                      overlay={
                        <Tooltip id={`tooltip-left`}>
                          Total amount of {datas?.stakingToken?.symbol} staked
                          in this pool
                        </Tooltip>
                      }
                    >
                      <Button className={`${cascading.tooltip_button}`}>
                        {/* <FaRegQuestionCircle /> */}
                        <FaQuestion />
                      </Button>
                    </OverlayTrigger>{" "}
                  </p>
                </div>
                <div className={`${cascading.listrow}`}>
                  <p className={`${cascading.label}`}>Deposit Fee:</p>
                  <p className={`${cascading.value}`}>
                    {toFixedNumber(datas?.depositFee / 10 ** 18)} %{" "}
                  </p>
                </div>

                {shouldShowBlockCountdown ? (
                  <div className={`${cascading.listrow}`}>
                    <p className={`${cascading.label}`}>
                      {hasPoolStarted ? "Ends in:" : "Starts in"}
                    </p>
                    {/* <p className={`${cascading.value}`}>598,957 blocks <FaClock /></p> */}
                    {blocksRemaining || blocksUntilStart ? (
                      <button
                        className={`${cascading.declabel}`}
                        onClick={() => {
                          window.open(
                            `${CHAINS[GetChainIndex()].EndBlock}/${
                              datas?.endBlock
                            }`,
                            "_blank"
                          );
                        }}
                        target="_blank"
                      >
                        {parseFloat(blocksRemaining).toLocaleString("en-Us")}{" "}
                        blocks <FaClock />{" "}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div className={`${cascading.linksec}`}>
                  <button
                    className={`${cascading.declabel}`}
                    onClick={() => {
                      window.open(
                        `${CHAINS[GetChainIndex()].Testnet}/${getPoolAddress(
                          datas?.contractAddress
                        )}`,
                        "_blank"
                      );
                    }}
                    target="_blank"
                  >
                    View Contract <FaExternalLinkAlt fontSize={10} />
                  </button>

                  <Link
                    className={`mt-3 ${cascading.contractlink}`}
                    onClick={() => {
                      ImportToken(
                        datas?.stakingToken?.address,
                        datas?.stakingToken?.symbol,
                        datas?.stakingToken?.decimal,
                        image
                      );
                    }}
                  >
                    Add to Metamask
                    <svg
                      viewBox="0 0 40 40"
                      color="text"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                      class="sc-bdnxRM buUsjs"
                    >
                      <path
                        d="M36.0112 3.33337L22.1207 13.6277L24.7012 7.56091L36.0112 3.33337Z"
                        fill="#E17726"
                      ></path>
                      <path
                        d="M4.00261 3.33337L17.7558 13.7238L15.2989 7.56091L4.00261 3.33337Z"
                        fill="#E27625"
                      ></path>
                      <path
                        d="M31.0149 27.2023L27.3227 32.8573L35.2287 35.0397L37.4797 27.3258L31.0149 27.2023Z"
                        fill="#E27625"
                      ></path>
                      <path
                        d="M2.53386 27.3258L4.77116 35.0397L12.6772 32.8573L8.9987 27.2023L2.53386 27.3258Z"
                        fill="#E27625"
                      ></path>
                      <path
                        d="M12.2518 17.6496L10.0419 20.9712L17.8793 21.3281L17.6048 12.8867L12.2518 17.6496Z"
                        fill="#E27625"
                      ></path>
                      <path
                        d="M27.762 17.6494L22.3129 12.7905L22.1207 21.3279L29.9581 20.9711L27.762 17.6494Z"
                        fill="#E27625"
                      ></path>
                      <path
                        d="M12.6772 32.8574L17.3989 30.5652L13.336 27.3809L12.6772 32.8574Z"
                        fill="#E27625"
                      ></path>
                      <path
                        d="M22.6009 30.5652L27.3226 32.8574L26.6637 27.3809L22.6009 30.5652Z"
                        fill="#E27625"
                      ></path>
                      <path
                        d="M27.3226 32.8575L22.6009 30.5653L22.9715 33.6399L22.9303 34.9301L27.3226 32.8575Z"
                        fill="#D5BFB2"
                      ></path>
                      <path
                        d="M12.6772 32.8575L17.0694 34.9301L17.042 33.6399L17.3989 30.5653L12.6772 32.8575Z"
                        fill="#D5BFB2"
                      ></path>
                      <path
                        d="M17.1518 25.3495L13.2262 24.1965L15.9988 22.92L17.1518 25.3495Z"
                        fill="#233447"
                      ></path>
                      <path
                        d="M22.848 25.3495L24.001 22.92L26.801 24.1965L22.848 25.3495Z"
                        fill="#233447"
                      ></path>
                      <path
                        d="M12.6773 32.8573L13.3635 27.2023L8.99876 27.3258L12.6773 32.8573Z"
                        fill="#CC6228"
                      ></path>
                      <path
                        d="M26.6364 27.2023L27.3227 32.8573L31.0149 27.3258L26.6364 27.2023Z"
                        fill="#CC6228"
                      ></path>
                      <path
                        d="M29.9581 20.9709L22.1207 21.3278L22.8482 25.3495L24.0011 22.92L26.8012 24.1965L29.9581 20.9709Z"
                        fill="#CC6228"
                      ></path>
                      <path
                        d="M13.2263 24.1965L15.9989 22.92L17.1519 25.3495L17.8793 21.3278L10.0419 20.9709L13.2263 24.1965Z"
                        fill="#CC6228"
                      ></path>
                      <path
                        d="M10.0419 20.9709L13.3361 27.3809L13.2263 24.1965L10.0419 20.9709Z"
                        fill="#E27525"
                      ></path>
                      <path
                        d="M26.8011 24.1965L26.6638 27.3809L29.958 20.9709L26.8011 24.1965Z"
                        fill="#E27525"
                      ></path>
                      <path
                        d="M17.8793 21.3278L17.1519 25.3494L18.0715 30.0985L18.2637 23.8396L17.8793 21.3278Z"
                        fill="#E27525"
                      ></path>
                      <path
                        d="M22.1205 21.3278L21.7499 23.8258L21.9283 30.0985L22.848 25.3494L22.1205 21.3278Z"
                        fill="#E27525"
                      ></path>
                      <path
                        d="M22.848 25.3496L21.9284 30.0987L22.601 30.5654L26.6638 27.381L26.8011 24.1967L22.848 25.3496Z"
                        fill="#F5841F"
                      ></path>
                      <path
                        d="M13.2262 24.1967L13.336 27.381L17.3989 30.5654L18.0714 30.0987L17.1518 25.3496L13.2262 24.1967Z"
                        fill="#F5841F"
                      ></path>
                      <path
                        d="M22.9303 34.93L22.9715 33.6398L22.6284 33.3378H17.3714L17.042 33.6398L17.0694 34.93L12.6772 32.8574L14.2145 34.1202L17.3302 36.2751H22.6696L25.7853 34.1202L27.3226 32.8574L22.9303 34.93Z"
                        fill="#C0AC9D"
                      ></path>
                      <path
                        d="M22.601 30.5653L21.9284 30.0986H18.0715L17.3989 30.5653L17.0421 33.6399L17.3715 33.3379H22.6285L22.9716 33.6399L22.601 30.5653Z"
                        fill="#161616"
                      ></path>
                      <path
                        d="M36.5875 14.3003L37.7542 8.61779L36.011 3.33337L22.6009 13.2846L27.7618 17.6493L35.0365 19.7768L36.6424 17.8964L35.9424 17.3886L37.0679 16.3728L36.2169 15.7003L37.3287 14.863L36.5875 14.3003Z"
                        fill="#763E1A"
                      ></path>
                      <path
                        d="M2.24573 8.61779L3.42615 14.3003L2.67123 14.863L3.78302 15.7003L2.93202 16.3728L4.05753 17.3886L3.35752 17.8964L4.96343 19.7768L12.2518 17.6493L17.399 13.2846L4.00263 3.33337L2.24573 8.61779Z"
                        fill="#763E1A"
                      ></path>
                      <path
                        d="M35.0365 19.777L27.7619 17.6495L29.958 20.9712L26.6638 27.3811L31.0149 27.3262H37.4797L35.0365 19.777Z"
                        fill="#F5841F"
                      ></path>
                      <path
                        d="M12.2517 17.6495L4.96332 19.777L2.53386 27.3262H8.99869L13.336 27.3811L10.0419 20.9712L12.2517 17.6495Z"
                        fill="#F5841F"
                      ></path>
                      <path
                        d="M22.1205 21.3276L22.6009 13.2843L24.701 7.56067H15.2988L17.3988 13.2843L17.8792 21.3276L18.0577 23.8531L18.0714 30.0984H21.9283L21.9421 23.8531L22.1205 21.3276Z"
                        fill="#F5841F"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            )}{" "}
          </div>
        </div>
      </div>

      {showmodal && (
        <Walletmodal show={showmodal} onHide={() => setShowmodal(false)} />
      )}
      {calshow && (
        <Calculatormodal
          show={calshow}
          isFarm={false}
          address={datas?.stakingToken?.address}
          symbol={datas?.stakingToken?.symbol}
          apy={datas?.apr}
          earningTokenPrice={datas?.earningTokenPrice}
          stakingTokenPrice={datas?.stakingTokenPrice}
          rewardToken={datas?.earningToken?.symbol}
          onHide={() => setcalshow(false)}
        />
      )}
      {dbrequire && (
        <DbRequiremodal
          show={dbrequire}
          onHide={() => setDbrequire(false)}
          objData={props}
        />
      )}

      {stakepoolmodal && (
        <Stakepoolmodal
          show={stakepoolmodal}
          onHide={() => setStakepoolmodal(false)}
          stake={props}
          image={image}
          onshowcalculator={() => setcalshow(true)}
        />
      )}
      {unstakepoolmodal && (
        <Unstakepoolmodal
          show={unstakepoolmodal}
          onHide={() => setUnStakepoolmodal(false)}
          unstake={props}
          image={image}
        />
      )}
    </>
  );
}
