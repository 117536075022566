import { consolelog } from "../lib/consolelog"

//hooks
import { UseAllCommonPairs, getTokens } from "./useTokens"
import { GetAmountsIn, GetAmountsOut } from "./useRouter"
import { toFixedNumber } from "../lib/FixedNumber"

export const UseTradeExactIn = async (amountIn, tokenA, tokenB) => {
    try {
        console.log('UseTradeExactIn', amountIn, tokenA, tokenB)
        const allowedPairs = await UseAllCommonPairs(tokenA, tokenB)
        let AddressArr = [tokenA, tokenB]
        let amountsIn = toFixedNumber(amountIn * 10 ** 18).toFixed(0)
        let { price, amountOut } = await GetAmountsOut(AddressArr, amountsIn)
        console.log('UseTradeExactIn', tokenA)
        for (let i = 0; i < allowedPairs.length; i++) {
            let pair = allowedPairs[i]
            let token0 = pair.token0
            let token1 = pair.token1

            console.log(pair, 'UseTradeExactIn_pair')

            console.log(!(token0.toLowerCase() == tokenA.toLowerCase()) && !(token1.toLowerCase() == tokenA.toLowerCase()) &&
                !(token1.toLowerCase() == tokenB.toLowerCase()) && !(token0.toLowerCase() == tokenB.toLowerCase()), 'UseTradeExactIn_for')

            // console.log(tokenA.toLowerCase() == token0.toLowerCase() && tokenB.toLowerCase() == token1.toLowerCase(),'UseTradeExactIn_for')

            if (!(token0.toLowerCase() == tokenA.toLowerCase()) && !(token1.toLowerCase() == tokenA.toLowerCase()) &&
                !(token1.toLowerCase() == tokenB.toLowerCase()) && !(token0.toLowerCase() == tokenB.toLowerCase())
            ) continue;


            // if(tokenA.toLowerCase() == token0.toLowerCase() && tokenB.toLowerCase() == token1.toLowerCase())  continue;

            if (pair.token0.toLowerCase() == tokenA.toLowerCase() || pair.token1.toLowerCase() == tokenA.toLowerCase()) {

                let placedAtoken = pair.token0.toLowerCase() == tokenA.toLowerCase() ? 1 : pair.token1.toLowerCase() == tokenA.toLowerCase() ? 2 : ''

                console.log(placedAtoken, 'placedAtoken')
                let interMidate = placedAtoken == 1 ? token1 : placedAtoken == 2 ? token0 : ''
                console.log(interMidate, 'interMidate')
                let finalPair = allowedPairs.find((val) => ((val.token0.toLowerCase() == interMidate.toLowerCase() && val.token1.toLowerCase() == tokenB.toLowerCase()) || (val.token0.toLowerCase() == tokenB.toLowerCase() && val.token1.toLowerCase() == interMidate.toLowerCase())))
                console.log(finalPair, 'finalPair')
                if (finalPair) {
                    let placedBtoken = finalPair.token0.toLowerCase() == interMidate.toLowerCase() ? 1 : finalPair.token1.toLowerCase() == interMidate.toLowerCase() ? 2 : ''
                    console.log(placedBtoken, 'placedBtoken')
                    let AmountIn = toFixedNumber(amountIn * 10 ** 18)
                    let firstPairOut = placedAtoken == 1 ? await GetAmountsOut([pair.token0, pair.token1], AmountIn) : await GetAmountsOut([pair.token1, pair.token0], AmountIn)
                    console.log(firstPairOut, 'firstPairOut')
                    let AmountGet = toFixedNumber(Object.values(firstPairOut)[1] * 10 ** 18).toFixed(0)
                    // let convertBtoA =placedAtoken == 1 ? await GetAmountsIn([pair.token0, pair.token1], AmountGet) : await GetAmountsIn([pair.token1, pair.token0], AmountGet)

                    let realizedLPFee =CalculateLpFee(amountIn,amountIn)

                    console.log('convertBtoA',realizedLPFee)
                    let finalOut = placedBtoken == 1 ? await GetAmountsOut([interMidate, tokenB], AmountGet) : await GetAmountsOut([interMidate, tokenB], AmountGet)
                    console.log(finalOut, 'finalOut')
                    console.log(finalOut, "UseTradeExactIn__finalOut")
                    console.log('Pair_order', tokenA, interMidate, tokenB)
                    let midprice = CalculateMidPrice(Object.values(finalOut)[1],Object.values(firstPairOut)[1], finalPair.reserveA, finalPair.reserveB, true)
                    let routePath = [tokenA,interMidate,tokenB]
                    let tokenASymbol = getTokens().find((val)=>(val.address.toLowerCase()==tokenA.toLowerCase()))
                    let tokenBSymbol = getTokens().find((val)=>(val.address.toLowerCase()==interMidate.toLowerCase()))
                    let tokenCSymbol = getTokens().find((val)=>(val.address.toLowerCase()==tokenB.toLowerCase()))
                    let route = `${tokenASymbol.symbol}>${tokenBSymbol.symbol}>${tokenCSymbol.symbol}`
                    if(amountOut  < Object.values(finalOut)[1]){
                        return {
                            priceImpact: midprice,
                            amountOut: Object.values(finalOut)[1],
                            routePath:routePath,
                            route:route,
                            realizedLPFee:realizedLPFee,
                            status:true
    
                        }
                    }else{
                        console.log('getAmounts > bestPrice')
                        return {
                            priceImpact: 0,
                            amountOut: 0,
                            routePath:0,
                            route:0,
                            realizedLPFee:0,
                            status:false
                        }
                    }
                    break;
                }
            }
        }
    } catch (err) {
        consolelog("useTradeExactIn__err", err, true)
    }
}

export const UseTradeExactOut =  async(amountOut,tokenA,tokenB)=>{
    try {
        console.log('UseTradeExactOut', amountOut, tokenA, tokenB)
        const allowedPairs = await UseAllCommonPairs(tokenA, tokenB)
        let AddressArr = [tokenA, tokenB]
        let amountsOut= toFixedNumber(amountOut * 10 ** 18).toFixed(0)
        let { price,  amountIn} = await GetAmountsIn(AddressArr, amountsOut)
        console.log('UseTradeExactOut', tokenA)
        for (let i = 0; i < allowedPairs.length; i++) {
            let pair = allowedPairs[i]
            let token0 = pair.token0
            let token1 = pair.token1

            console.log(pair, 'UseTradeExactOut_pair')

            console.log(!(token0.toLowerCase() == tokenA.toLowerCase()) && !(token1.toLowerCase() == tokenA.toLowerCase()) &&
                !(token1.toLowerCase() == tokenB.toLowerCase()) && !(token0.toLowerCase() == tokenB.toLowerCase()), 'UseTradeExactIn_for')

            // console.log(tokenA.toLowerCase() == token0.toLowerCase() && tokenB.toLowerCase() == token1.toLowerCase(),'UseTradeExactIn_for')

            if (!(token0.toLowerCase() == tokenA.toLowerCase()) && !(token1.toLowerCase() == tokenA.toLowerCase()) &&
                !(token1.toLowerCase() == tokenB.toLowerCase()) && !(token0.toLowerCase() == tokenB.toLowerCase())
            ) continue;


            // if(tokenA.toLowerCase() == token0.toLowerCase() && tokenB.toLowerCase() == token1.toLowerCase())  continue;

            if (pair.token0.toLowerCase() == tokenA.toLowerCase() || pair.token1.toLowerCase() == tokenA.toLowerCase()) {

                let placedAtoken = pair.token0.toLowerCase() == tokenA.toLowerCase() ? 1 : pair.token1.toLowerCase() == tokenA.toLowerCase() ? 2 : ''

                console.log(placedAtoken, 'placedAtoken')
                let interMidate = placedAtoken == 1 ? token1 : placedAtoken == 2 ? token0 : ''
                console.log(interMidate, 'interMidate')
                let finalPair = allowedPairs.find((val) => ((val.token0.toLowerCase() == interMidate.toLowerCase() && val.token1.toLowerCase() == tokenB.toLowerCase()) || (val.token0.toLowerCase() == tokenB.toLowerCase() && val.token1.toLowerCase() == interMidate.toLowerCase())))
                console.log(finalPair, 'finalPair')
                if (finalPair) {
                    let placedBtoken = finalPair.token0.toLowerCase() == interMidate.toLowerCase() ? 1 : finalPair.token1.toLowerCase() == interMidate.toLowerCase() ? 2 : ''
                    console.log(placedBtoken, 'placedBtoken')
                    let AmountOut = toFixedNumber(amountOut * 10 ** 18)
                    let firstPairOut = placedAtoken == 1 ? await GetAmountsIn([pair.token0, pair.token1], AmountOut) : await GetAmountsIn([pair.token1, pair.token0], AmountOut)
                    console.log(firstPairOut, 'firstPairOut')
                    let AmountGet = toFixedNumber(Object.values(firstPairOut)[1] * 10 ** 18).toFixed(0)
                    // let convertBtoA =placedAtoken == 1 ? await GetAmountsIn([pair.token0, pair.token1], AmountGet) : await GetAmountsIn([pair.token1, pair.token0], AmountGet)

                    

                    // console.log('convertBtoA',realizedLPFee)
                    let finalOut = placedBtoken == 1 ? await GetAmountsIn([interMidate, tokenB], AmountGet) : await GetAmountsIn([interMidate, tokenB], AmountGet)
                    console.log(finalOut, 'finalOut')
                    let realizedLPFee = CalculateLpFee(Object.values(finalOut)[1],Object.values(finalOut)[1])
                    console.log(finalOut, "UseTradeExactOut__finalOut",realizedLPFee)
                    console.log('Pair_order', tokenA, interMidate, tokenB)
                    let midprice = CalculateMidPrice(Object.values(finalOut)[1],Object.values(firstPairOut)[1], finalPair.reserveA, finalPair.reserveB, true)
                    let routePath = [tokenA,interMidate,tokenB]
                    let tokenASymbol = getTokens().find((val)=>(val.address.toLowerCase()==tokenA.toLowerCase()))
                    let tokenBSymbol = getTokens().find((val)=>(val.address.toLowerCase()==interMidate.toLowerCase()))
                    let tokenCSymbol = getTokens().find((val)=>(val.address.toLowerCase()==tokenB.toLowerCase()))
                    let route = `${tokenASymbol.symbol}>${tokenBSymbol.symbol}>${tokenCSymbol.symbol}`
                    if(amountIn  > Object.values(finalOut)[1]){
                        return {
                            priceImpact: midprice,
                            amountIn: Object.values(finalOut)[1],
                            routePath:routePath,
                            route:route,
                            realizedLPFee:realizedLPFee,
                            status:true
    
                        }
                    }else{
                        console.log('getAmounts > bestPrice')
                        return {
                            priceImpact: 0,
                            amountOut: 0,
                            routePath:0,
                            route:0,
                            realizedLPFee:0,
                            status:false
                        }
                    }
                    break;
                }
            }
        }
    } catch (err) {
        consolelog("UseTradeExactOut__err", err, true)
    }
}


export const CalculateMidPrice = (amountOut,tokenAval, ReserveA, ReserveB, swapsec) => {
    try {
        let midprice
        if (swapsec) {
            midprice = Number(ReserveA) / Number(ReserveB)
        }
        else {
            midprice = Number(ReserveB) / Number(ReserveA)
        }
        var exactQuote = (midprice) * parseFloat(tokenAval)
        var priceImpact = ((exactQuote - amountOut) / exactQuote)
        priceImpact = (priceImpact - (0.25 / 100)) * 100
        console.log(priceImpact, "CalculateMidPrice")
        return priceImpact
    } catch (err) {
        consolelog('CalculateMidPrice__err', err, true)
    }
}

export const CalculateLpFee = (amountIn,convertBtoA)=>{
    try{
        console.log(amountIn,convertBtoA,'CalculateLpFee')
        let TotalIn = parseFloat(amountIn)+parseFloat(convertBtoA)
        let realizedLPFee = TotalIn * 0.25 / 100
        console.log(parseFloat(realizedLPFee).toFixed(16),'realizedLPFee',TotalIn)
        return parseFloat(realizedLPFee).toFixed(16)
    }catch(err){
        console.log('CalculateLpFee__err',err)
    }
}