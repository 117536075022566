import { consolelog } from "../lib/consolelog"

export const SlippageValidation = (slippageInput, userSlippageTolerance) => {
    try {
        consolelog('SlippageValidation',{slippageInput,userSlippageTolerance},true)
        const slippageInputIsValid = slippageInput === '' || (userSlippageTolerance / 100).toFixed(2) === parseFloat(slippageInput).toFixed(2)
        let slippageError = ''
        if (slippageInput !== '' && !slippageInputIsValid) {
            slippageError = 'Enter a valid slippage percentage'
        } else if (slippageInputIsValid && userSlippageTolerance < 50) {
            slippageError = 'Your transaction may fail'
        } else if (slippageInputIsValid && userSlippageTolerance > 500) {
            slippageError = 'Your transaction may be frontrun'
        } else {
            slippageError = ''
        }
        return slippageError
    } catch (err) {
        consolelog('SlippageValidation__err', err, true)
    }
}