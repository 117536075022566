// import { createSlice } from "@reduxjs/toolkit";


// export const adminSlice = createSlice({
//   name: 'admin',
//   initialState: {
//     walletStatus: localStorage.getItem('walletStatus') === undefined ? 'disconnect' : localStorage.getItem('walletStatus'),


//   },
//   reducers: {
//     walletControl: (state, action) => {
//       state.walletStatus = action.payload

//     },


//   }

// })
// export const { walletControl } = adminSlice.actions
// export default adminSlice.reducer


// import constant
import {
    WALLET_STATUS
} from '../constants';

const initialValue = {
    walletStatus: ''
}

//  console.log("initialValue----->>>",initialValue);
const admin = (state = initialValue, action) => {

    switch (action.type) {
        case WALLET_STATUS:
            return {
                ...state,
                walletStatus: action.payload
            };
       
        default:
            return state;
    }
}

export default admin;