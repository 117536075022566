// import constant
import {
    WALLET_ADDRESS,
    WALLET_DATA,
    NATIVE_BALANCE,
    XTOKEN_BALANCE
} from '../constants';

const initialValue = {
    walletaddress: '',
    walletData: {},
    nativebalance : 0,
    xtokenbalance:0
}

//  console.log("initialValue----->>>",initialValue);
const wallet = (state = initialValue, action) => {

    switch (action.type) {
        case WALLET_ADDRESS:
            return {
                ...state,
                walletaddress: action.payload
            };
        case WALLET_DATA:
            return {
                ...state,
                walletData: action.payload
            };
        case NATIVE_BALANCE:
            return{
                ...state,
                nativebalance: action.payload
            };
        case XTOKEN_BALANCE:
            return{
                ...state,
                xtokenbalance: action.payload
            };
        default:
            return state;
    }
}

export default wallet;