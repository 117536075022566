import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import cascading from "../assests/css/howToConnect.module.css";
import { IoMdClose } from "react-icons/io";

export default function HowToConnectModal(props) {
  const { show, handleClose } = props;
  return (
    <div className={`${cascading.walletmodalstyle}`}>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        className={`${cascading.modalstyle}`}
        backdrop="static"
      >
        <Modal.Header
          className={`${cascading.modalclr} ${cascading.modalheader}`}
        >
          <h4 className={`${cascading.modaltitle} mb-0`}>How To Connect ?</h4>

          <IoMdClose
            className={`${cascading.closebtn}`}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <div className={`${cascading.walletrow} text-center`}>
            <p className={`${cascading.footerdesc}`}>
            To connect your MetaMask wallet, follow these steps:
            <h6> Install MetaMask:</h6>
            If you haven't already, install the MetaMask extension for your web browser (available for Chrome, Firefox, Brave, and Edge).

            Open MetaMask: Click on the MetaMask extension icon in your browser's toolbar.

            Create or Import Wallet: If you're new to MetaMask, you'll need to create a new wallet and set up a password. If you already have a wallet, click on "Import wallet" and enter your seed phrase (12-word backup phrase) to restore your existing wallet.

            Connect to a Site or DApp:

            Many decentralized applications (DApps) and websites that interact with blockchain networks will prompt you to connect your wallet.
            Alternatively, you can manually connect by clicking on your account icon in the top right corner of MetaMask, copying your wallet address, and pasting it where needed.

            Authorize the Connection: When you connect MetaMask to a site or DApp for the first time, you'll usually be prompted to authorize the connection. Confirm the connection within MetaMask.

            Manage Connected Sites: You can manage which sites have access to your MetaMask wallet by clicking on the account icon, selecting "Connected Sites," and adjusting permissions as needed.

            </p>

          </div>
        </Modal.Body>
        {/* <Modal.Footer
          className={`${cascading.modalclr} ${cascading.modalfooter}`}
        >
          <p className={`${cascading.footerdesc}`}>
            Haven't got a crypto wallet yet?
          </p>
          <Button className={`${cascading.btnstyle}`} onClick={props.onHide}>
            Learn How to Connect
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
}
